import { ClassNames } from "@emotion/react";
import {
  Avatar,
  Box,
  MenuItem,
  Select,
  tabClasses,
  Typography,
  useTheme,
  Paper,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import { ArrowCircleDown } from "@mui/icons-material";
import moment from 'moment';
import { parse } from "path";

const useStyles = makeStyles({
  earnings: {
    padding: "2px 6px 2px 6px",
    color: "#3CB99E",
    backgroundColor: "rgba(60, 185, 158, 0.14)",
    fontSize: "18px",
    fontWeight: 600,
    borderRadius: "10px",
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
});
interface EN_DATA {
  date: 'string';
  earnings: string;
}
interface Props {
  data: Array<EN_DATA>;
  setRange: (val:string) => void;
  range: string;
}
const Earnings:React.FC<Props> = ({ data, setRange, range }) => {
  const theme = useTheme();
  const classes = useStyles();
  // const ranges = [{id: "week", label: '3 Months'}, {id: "month", label: '1 Year'}];
  const types = [{id: 1, label: "actual"}, {id: 2, label: "difference"}];

  const [type, setType] = useState('actual');
  const getLabel = () => {
    let arr = [...data]
    let res = []
    res = arr.map(obj => {
        
        if (range === 'week') {
            const options: any = { month: 'short', day: 'numeric' };
            return (new Date(obj.date)).toLocaleDateString('en-US', options)
        }
        if (range === 'month') {
            const options: any = { month: 'short' };
            return (new Date(obj.date)).toLocaleDateString('en-US', options)
        }
    }) as unknown as Array<string>
    return res
}
 
  const getValue = () => {
    let arr = [...data]
    let res = []
    res = arr.map(obj=>parseFloat(obj.earnings).toFixed(2)) as Array<string>
    return res
  }

  const getValueDiff = () => {
    let arr = [...data]
    let res = []
    res = arr.map(obj => parseFloat(obj.earnings).toFixed(2)) as Array<string>
    var newA = [];
    /*@ts-ignore*/
    for (var i = 0; i < res.length; i++)  newA.push(res[i] - res[i - 1])
    console.log(newA,'new array')
    return newA;
  }
  // const getStartDate = (range:string) => {
  //   switch (range) {
  //     case 'Today':
  //       return moment().format('YYYY-MM-DD') as string
  //     case 'This Week':
  //       return moment().subtract(7,'d').format('YYYY-MM-DD') as string
  //     case 'This Month':
  //       return moment().subtract(31,'d').format('YYYY-MM-DD') as string
  //   }
  // }

  return (
    <Paper
      sx={{
        boxShadow: "none",
        border: "1px solid #00000020",
        borderRadius: "10px",
      }}
    >
  {data.length > 0 ?
      <Box
        sx={{ p: 2 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          mb={7}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex">
            <Typography variant="h6">Earnings (Rs)</Typography>
            <div className={classes.earnings}>{`Rs. ${data && data.length && parseFloat(data[data.length - 1].earnings).toFixed(2)}`}</div>
          </Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: "row",
              width: "320px",
            }}
          >
            {/* <Box display="flex" sx={{ padding: "2px 5px 2px 5px" }}>
              <div style={{ color: "rgb(255, 150, 150)" }}>
                <ArrowCircleDown />
              </div>
              <Typography>21% from last week</Typography>
            </Box> */}
            <Select
              sx={{ backgroundColor: "rgb(245,245,245)", height: "35px" }}
              className="primary"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              {types.map((range, i) => (
                    <MenuItem key={i} value={range.label}>
                        {range.label.toUpperCase()}
                    </MenuItem>
                ))}
            </Select>
          </div>
        </Box>
        <Box height={295} width="95%">
          <Line
            data={(canvas) => {
              const ctx = canvas.getContext("2d");
              const g = ctx?.createLinearGradient(0, 0, 0, 290);

              g?.addColorStop(0, "rgba(255, 255, 255, 0.3)");

              let color = "#538ADC";
              let labels = getLabel();

              return {
                labels,
                datasets: [
                  {
                    // fill: true,
                    label: type,
                    data: type==='actual'? getValue():getValueDiff(),
                    borderColor: color,
                    borderWidth: 2,
                    pointBackgroundColor: color,
                    // backgroundColor: g,
                    // tension: 0,
                    // pointRadius: 0,
                    // pointHoverRadius: 4,
                    // pointHoverBackgroundColor: "#fff",
                    // pointHoverBorderWidth: 3,
                  },
                ],
              };
            }}
            options={{
              scales: {
                xAxis: {
                  // type: 'time',
                  grid: {
                    display: false,
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                  ticks: {
                    color: theme.palette.text.secondary,
                  },
                },
                yAxis: {
                  title: {
                    display: true,
                    // text: "Earnings",
                    padding: {
                      top: 0,
                      bottom: 8,
                    },
                    color: "grey",
                    font: {
                      weight: "500",
                      size: 12,
                    },
                  },
                  ticks: {
                    color: theme.palette.text.secondary,
                  },
                  suggestedMin: 0,
                  grid: {
                    borderDash: [10],
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  caretSize: 0,
                  mode: "index",
                  intersect: false,
                  yAlign: "center",
                  displayColors: false,
                  caretPadding: 16,
                  titleFont: {
                    weight: "400",
                  },
                  bodyFont: {
                    weight: "500",
                  },
                },
              },
              interaction: {
                mode: "index",
                intersect: false,
              },
            }}
          />
        </Box>
      </Box> : 'Loading' }
    </Paper>
  );
};

export default Earnings;
