import { Box, Typography, Paper } from "@mui/material";
import React from "react";
import { Doughnut } from "react-chartjs-2";

interface EN_DATA {
  healthy?: number;
  inactive?: number;
 }
interface Props {
  data: EN_DATA;
}
const Health: React.FC<Props> = ({data}) => {
// console.log(data, 'health data')
  return (
    <Paper
      sx={{
        height: "420px",
        boxShadow: "none",
        border: "1px solid #00000020",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" mb={5}>
          Health Status
        </Typography>
        <Box
          sx={{
            width: 1,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            justifyItems: "center",
          }}
        >
          <Box width={190} alignSelf="center" position="relative">
            <Doughnut
              style={{ position: "relative", zIndex: 2 }}
              data={(canvas) => {
                return {
                  datasets: [
                    {
                      data: [data.healthy, data.inactive],
                      backgroundColor: [
                        "rgb(255, 99, 132)",
                        "rgb(54, 162, 235)",
                      ],
                      spacing: 1,
                      hoverOffset: 0,
                      borderWidth: 0,
                      borderRadius: 50,
                      cutout: "80%",
                    },
                  ],
                  labels: ["Healthy", "Unhealthy"],
                };
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                  },
                },
              }}
            />
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: 70,
                right: 0,
                left: 0,
                mx: "auto",
                pointerEvents: "none",
                textAlign: "center",
              }}
            >
              <Typography fontSize={28} fontWeight={700} lineHeight="1.2em">
                {  (data.healthy as number/(data.inactive as number) as any ).toPrecision(2) * 100 + '%'}
              </Typography>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  fontFamily: "Poppins !important",
                }}
              >
                Health
              </Typography>
            </Box>
          </Box>
          <Box mt={3} ml={3}>
            {[
              {
                label: "Healthy Vehicles",
                value: data.healthy,
                color: "rgb(255, 99, 132)",
              },
              {
                label: "Unhealthy Vehicles",
                value: data.inactive,
                color: "rgb(54, 162, 235)",
              },
            ].map(({ label, value, color }, i) => (
              <Box
                key={i}
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  // width: 1,
                  pl: 2.75,
                  mb: 2.5,
                  "& .value": {
                    mb: 1,
                    lineHeight: "1.2em",
                    fontSize: 25,
                    fontWeight: 700,
                    color: color,
                    "&:before": {
                      content: '""',
                      position: "absolute",
                      top: 4,
                      left: 0,
                      width: 14,
                      height: 14,
                      bgcolor: color,
                      borderRadius: "2px",
                    },
                  },
                  "& .title": {
                    color: "black",
                    fontSize: 16,
                    lineHeight: "1.4em",
                  },
                }}
              >
                <span className="value">{value}</span>
                <span className="title">{label}</span>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default Health;
