export const Address = ({ address, title }: { address: any; title: string }) => {
	return (
		<div>
			<h1
				style={{
					fontSize: "14px",
					fontWeight: "bold",
					margin: "0 0 10px 0"
				}}
			>
				{title}:
			</h1>
			<ul
				style={{
					listStyle: "none",
					paddingLeft: "0px"
				}}
			>
				<li>{address.address1}</li>
				<li>{address.address2}</li>
				<li>{address.city}</li>
				<li>{address.state}</li>
				<li>{address.country}</li>
				<li>{address.postalCode}</li>
			</ul>
		</div>
	);
};