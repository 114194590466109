import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import Health from "./health/Health";
import Bookings from "./bookings/Bookings";
import NewUserCount from "./newUsers/NewUsers";
import Total from "./infoCards/Total";
import Consumption from "./energyConsumption/Consumption";
import DropDownComp from "./selectComp/dropdowns";
import Distance from "./distance/Distance";
import Earnings from "./earnings/Earnings";
import Assets from "./infoCards/Assets";
import { DisabledByDefault } from "@mui/icons-material";
import { getBookingData } from '../../../../../services/BookingService';
import { getNewUserData } from '../../../../../services/NewUserService';
import { getConsumptionData } from '../../../../../services/ConsumptionService';
import { getAssetData } from '../../../../../services/AssetsService';
import { getDistanceData } from '../../../../../services/DistanceService';
import { getEarningData } from '../../../../../services/EarningService'
import moment from "moment";
import { getHealthData } from '../../../../../services/HealthService';
import { AnyIfEmpty } from "react-redux";

interface Props {
  companies: Array<any>
}
const GridComp: React.FC<Props> = ({companies}) => {
  const [product, setProduct] = useState("Bolt");
  const [productType, setProductType] = useState("Master");
  const [bookingData, setBookingData] = useState([])
  const [userData, setuserData] = useState([])
  const [consumptionData, setComsumptionData] = useState([])
  const [distanceData, setDistanceData] = useState([])
  const [healthData, setHealthData] = useState({})
  const [earningData, setEarningData] = useState([])
  const [assetData, setAssetData] = useState([])
  const [id, setId] = useState(companies[0].id)
  const [startDate, setStartDate] = useState(
    {
      'booking': moment().subtract(7, 'd').format('YYYY-MM-DD'),
      'consumption': moment().subtract(7, 'd').format('YYYY-MM-DD'),
      'users': moment().subtract(7, 'd').format('YYYY-MM-DD'),
      'distance': moment().subtract(7, 'd').format('YYYY-MM-DD'),
      'earning': moment().subtract(7, 'd').format('YYYY-MM-DD'),
      'asset': moment().subtract(7, 'd').format('YYYY-MM-DD')
    })

    // const [bookingRange, setBookingRange] = useState('week')
    // const [earningRange, setEarningRange] = useState('week')
    // const [energyRange, setEnergyRange] = useState('week')
    // const [userRange, setUserRange] = useState('week')
    // const [assetRange, setAssetRange] = useState('week')
    const [range, setRange] = useState('week')
  const bookingFunction = async (type: string ) => {
    let res = await getBookingData(type,range)
    /*@ts-ignore*/
    setBookingData(res)
  }

  const earningFunction = async (type: string) => {
    let res = await getEarningData(type, range)
    // console.log(res)
        /*@ts-ignore*/
    setEarningData(res)
  }

  const newUsersFunction = async (type: string) => {
    let res = await getNewUserData(type, range)
    console.log('getNewUserData', res)
    /*@ts-ignore*/
    setuserData(res)
  }

  const energyConsumptionFunction = async () => {
    let res = await getConsumptionData(range)
    /*@ts-ignore*/
    setComsumptionData(res)
  }

  const assetsFunction = async (type: string) => {
    let res = await getAssetData(type, range)
    /*@ts-ignore*/
    setAssetData(res)
  }

  const healthFunction = async (type: string) => {
    let res = await getHealthData(type)
    // console.log(res,'heath-data-rec')
    setHealthData(res)
  }

  const distanceFunction = async (isMaster: boolean, start: string = startDate['distance']) => {
    let res = await getDistanceData(isMaster, start, moment().format('YYYY-MM-DD'), id.length>0?id:companies[0].id)
    // console.log(res)
    setDistanceData(res)
  }

  useEffect(() => {
    // console.log(product, productType, id)
    // if (id != undefined) {
      // console.log(product, productType, id, 'part-2')
      if (product === 'Bolt') {
        // if (productType === 'Company') {
        //   bookingFunction('bolt', false);
        //   newUsersFunction('bolt', false);
        //   assetsFunction('bolt', false);
        //   energyConsumptionFunction(false);
        //   earningFunction('bolt', false)
        // }
        // else if (productType === 'Master') {
          bookingFunction('bolt');
          newUsersFunction('bolt');
          assetsFunction('bolt');
          energyConsumptionFunction();
          // healthFunction('bolt');
          earningFunction('bolt')
        // }
      }
      if (product === 'Lease') {
        // if (productType === 'Company') {
        //   bookingFunction('lease', false);
        //   newUsersFunction('lease', false);
        //   // assetsFunction('lease', false);
        //   distanceFunction(false);
        //   earningFunction('lease', false)
        // }
        // else if (productType === 'Master') {
          bookingFunction('lease');
          newUsersFunction('lease');
          assetsFunction('lease');
          distanceFunction(true)
          healthFunction('lease');
          // earningFunction('lease')
        // }
      // }
    }
  }, [product, range])

  // useEffect(() => {
  //   if (product === 'Bolt') {
  //     bookingFunction('bolt');
  //   } 
  //   if (product === 'Lease') {
  //     bookingFunction('lease');
  //   }
  // }, [range])

  // useEffect(() => {
  //   if (product === 'Bolt') {
  //     energyConsumptionFunction();
  //   } 
  //   // if (product === 'Lease') {
  //   //   bookingFunction('lease');
  //   // }
  // }, [energyRange])

  // useEffect(() => {
  //   if (product === 'Bolt') {
  //     earningFunction('bolt');
  //   } 
  //   if (product === 'Lease') {
  //     earningFunction('lease');
  //   }
  // }, [earningRange])

  // useEffect(() => {
  //   if (product === 'Bolt') {
  //     newUsersFunction('bolt');
  //   } 
  //   if (product === 'Lease') {
  //     newUsersFunction('lease');
  //   }
  // }, [userRange])

  // useEffect(() => {
  //   if (product === 'Bolt') {
  //     assetsFunction('bolt');
  //   } 
  //   if (product === 'Lease') {
  //     assetsFunction('lease');
  //   }
  // }, [assetRange])

  const changeProduct = (product: string) => {
    setProduct(product);
    setBookingData([]);
    setuserData([])
    setComsumptionData([])
    setDistanceData([])
    setHealthData({})
    setAssetData([])
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={4} sx={{ marginTop: "10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DropDownComp setProduct={changeProduct} setProductType={setProductType} companies={companies} setId={setId} range={range} setRange={setRange} />
            <Total bookingData={bookingData} totalUsers={userData} earningData={earningData} totalAssets={assetData} />
          </div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={7} sx={{ marginTop: "10px" }}>
          <Bookings bookingData={bookingData} setRange={setRange} range={range}/>
        </Grid>
        <Grid item xs={6} sx={{ marginTop: "10px" }}>
          <NewUserCount data={userData} setRange={setRange} range={range}/>
        </Grid>
        <Grid item xs={6} sx={{ marginTop: "10px" }}>
          {product === "Bolt" ? <Consumption data={consumptionData} setRange={setRange} range={range} /> : <Distance data={distanceData} updateStart={(date, type) => {
            let start: any = { ...startDate }
            start[type] = date
            setStartDate(start)
            distanceFunction(productType === 'Comapny' ? false : true, date)
          }} />}
        </Grid>
        {product === "Lease" ? <Grid item xs={6} sx={{ marginTop: "10px" }}>
          <Health data={healthData} />
        </Grid> : <div></div>}
        { product === "Bolt" ? <Grid item xs={6} sx={{ marginTop: "10px" }}>
          <Earnings data={earningData} setRange={setRange} range={range}/>
        </Grid> : <div></div> }
        <Grid item xs={6} sx={{ marginTop: "10px" }}><Assets data={assetData} setRange={setRange} range={range}/></Grid>
        {/* {product === 'Bolt' && productType === 'Master' && <Grid item xs={12}><Assets data={assetData} /></Grid>} */}
      </Grid>
    </Box>
  );
};

export default GridComp;
