export const getAssetData = async (type, range) => {
  // let url;
  // if(type==='bolt')
  try {
    let url = `https://datafeed.revos.in/housekeeping/${type}/assetGrowth?token=1234&timeframe=${range}`
    //  else if(type==='lease')  url = `https://datafeed.revos.in/housekeeping/${type}/totalassets?token=1234&id=5cfa443da7b11b00073f9657`;
  
    let res = await fetch(url, {
      method: "GET",
    })
      res = await res.json();
      return res;
  } catch (e) {
    return {data: []}
  }
};
