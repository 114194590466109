import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const UsersTable: React.FC = () => {

    const [appUsersData, setAppUsersData] = useState<Array<any>>([])

    useEffect(() => {
        topAppUsersData()
    }, [])

    const topAppUsersData = async () => {
        try {
            let url = 'https://datafeed.dev.revos.in/service/bolt/stats/topAppUsersList'
            let res = await fetch(url, {
                headers: { token: '1234' },
                method: "GET",
            })
            let response = await res.json() as Array<any>;
            setAppUsersData(response as Array<any>)
         }
        catch (e) {
            return e
         }
    }

    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell>User Email</TableCell>
                    <TableCell align="center">Phone</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">openId</TableCell>
                    <TableCell align="center">Total Calls</TableCell>
                    <TableCell align="center">Package Name</TableCell>
                    <TableCell align="center">Exec. Time (s)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {appUsersData.map((row) => (
                    <TableRow
                        key={row.userId}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.email}
                        </TableCell>
                        <TableCell align="center">{row.phone}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.openId}</TableCell>
                        <TableCell align="center">{row.count}</TableCell>
                        <TableCell align="center">{row.package}</TableCell>
                        <TableCell align="center">{(Math.round(row.avgExecTime*100)/100).toFixed(2)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    )
}

export default UsersTable
