export const downloadInvoice = async (invoiceId: string) => {
    try {
        console.log(invoiceId);
		const data: any = await fetch(
			`${process.env.REACT_APP_REPORT_SERVICE_URL}/v2/deviceorderinvoice?id=${invoiceId}&format=pdf`,
			{
				headers: {
					token: "1234"
				}
			}
        );
		if (data) {
            const jsonData = await data.json();
            console.log(jsonData);
			if (jsonData.status === 200) {
				// const url = window.URL.createObjectURL(jsonData.file);
				window.open(jsonData.file);
			} else {
				alert(jsonData.message);
			}
		} else if (data.message) {
			alert(data.message);
		}
	} catch (error: any) {
		alert(error.message);
	}
};
