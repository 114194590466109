import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import onboard from "../../assets/onboard.svg";
import * as localStoreOperations from "../../config/storageUtils";
import { AuthContext } from "../../context/auth";

const Onboard: FC = () => {
  const history = useHistory();
  const context: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState({
    open: true,
    data: {
      email: "",
      password: "",
    },
  });

  const [message, setMessage] = useState("Welcome to REVOS Housekeeping");

  // Main function:

  async function loginFunction(loginData: any) {
    const res = await fetch("https://auth.revos.in/company/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: login.data.email,
        password: login.data.password,
      }),
    }).then((res) => res.json());

    setLoading(false);

    if (res.message !== "SUCCESS" || !res.data.user) {
      setMessage("Invalid Credentials");
      return;
    }

    const { token, user } = res.data;
    user.role = "ADMIN";
    console.log({ token, user });

    localStorage.setItem("authorizationToken", token);
    if (user && user.email === "tester@revos.in") {
      context.login(user);
      history.push("/app/deployment/company");
    } else if (user && user.email === "marketing@revos.in") {
      context.login(user);
      history.push("/app/orders");
    } else if (user && user.email === "operations@revos.in") {
      context.login(user);
      history.push("/app/orders");
    } else if (user && user.email === "courier@revos.in") {
      context.login(user);
      history.push("/app/orders");
    } else if (user && user.email === "admin@revos.in") {
      context.login(user);
      history.push("/app/overview");
    } else {
      history.push("/onboard");
      setMessage("Invalid Credentials");
    }

    if (loginData.successCallback) {
      loginData.successCallback(res);
    }
  }

  async function handleLogin(e: any) {
    setLoading(true);
    e.preventDefault();

    // user details
    const data = {
      username: login.data.email,
      password: login.data.password,
    };

    // login data
    const loginData = {
      data,
      successCallback: (result: any) => {
        console.log("result::", result);
        if (
          context.user &&
          (context.user.email === "tester@revos.in" || context.user.email === "taijas@bolt.earth")
        ) {
          history.push("/app/development/company");
        }
        if (
          context.user &&
          (context.user.email === "marketing@revos.in" ||
            context.user.email === "taijas@bolt.earth")
        ) {
          history.push("/app/orders");
        }
        if (
          context.user &&
          (context.user.email === "operations@revos.in" ||
            context.user.email === "taijas@bolt.earth")
        ) {
          history.push("/app/orders");
        }
        if (
          context.user &&
          (context.user.email === "courier@revos.in" || context.user.email === "taijas@bolt.earth")
        ) {
          history.push("/app/orders");
        }
        if (
          context.user &&
          (context.user.email === "admin@revos.in" || context.user.email === "taijas@bolt.earth")
        ) {
          history.push("/app/overview");
        } else {
          setLoading(false);
          history.push("/onboard");
          setMessage("Invalid Credentials");
        }
      },
      loader: true,
      // successAction: context.login(data),
      errorCallback: (result: any) => {
        history.push("/onboard");
      },
    };
    loginFunction(loginData);
  }

  return (
    <StyledLogin>
      {loading ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            opacity: 0.8,
            background: "black",
            position: "absolute",
            top: 0,
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress></CircularProgress>
        </div>
      ) : (
        ""
      )}

      <div className="onboard-image">
        <img src={onboard} alt="" />
      </div>
      <div className="form">
        <Typography
          style={{
            marginLeft: "10px",
            fontSize: "20px",
            marginBottom: "0px",
            marginTop: "13px",
            letterSpacing: "1px",
            fontWeight: "bold",
          }}
          color="secondary"
        >
          {message}
        </Typography>
        <Box
          style={{
            width: "50%",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            style={{ width: "90%", margin: "30px" }}
            variant="outlined"
            label="Email Id"
            value={login.data.email}
            required
            onChange={(e: any) => {
              setLogin({
                ...login,
                data: {
                  email: e.target.value,
                  password: login.data.password,
                },
              });
            }}
          />
          <TextField
            style={{ width: "90%" }}
            variant="outlined"
            label="Password"
            value={login.data.password}
            type="password"
            required
            onChange={(e: any) => {
              setLogin({
                ...login,
                data: {
                  email: login.data.email,
                  password: e.target.value,
                },
              });
            }}
          />

          <Button
            style={{
              width: "90%",
              margin: "30px",
              marginBottom: "10px",
              padding: "15px",
            }}
            variant="contained"
            color="primary"
            onClick={handleLogin}
          >
            Log In
          </Button>
        </Box>
      </div>
    </StyledLogin>
  );
};

const StyledLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 97vh;
  .onboard-image {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: black;
    img {
      width: 80%;
      margin-right: 0;
      margin-left: auto;
    }
  }
  .form {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export default Onboard;
