import React from "react";
import { Box, Select, MenuItem, Paper } from "@mui/material";

interface Props {
  setProduct: (product: string) => void;
  setProductType: (productType: string) => void;
  companies: Array<any>
  setId: (id: string) => void;
  setRange: (val:string) => void;
  range: string;
}

const DropDownComp: React.FC<Props> = ({ setProduct, setProductType, companies, setId, setRange, range }) => {

  const ranges = [{id: "week", label: '3 Months'}, {id: "month", label: '1 Year'}];

  return (
    <Paper sx={{ width: "120%", boxShadow: "none" }}>
      <Box
        sx={{
          paddingTop: "2%",
          paddingLeft: "5%",
          paddingRight: "6%",
          width: "100%",
          justifyContent: "space-around",
          display: "flex",
        }}
      >
        {/* <Select
          sx={{ width: "34%", backgroundColor: "rgb(245,245,245)" }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Master"
          defaultValue={companies[0].id}
          onChange={(event: any) => {
            // console.log(event.target.value,'value')
             setId(event.target.value);
             event.target.value === companies[0].id ? setProductType('Master') : setProductType('Company')
            }}
        >
         {companies?.map((obj) => (
          <MenuItem value={obj.id}>{obj.name}</MenuItem>))}
      </Select> */}
      <Select
        sx={{ width: "34%", backgroundColor: "rgb(245,245,245)" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Stack"
        defaultValue="Bolt"
        onChange={(event) => {
          setProduct(event.target.value);
        }}
      >
        <MenuItem value="Bolt">Bolt</MenuItem>
        <MenuItem value="Lease">Lease</MenuItem>
      </Select>

      <Select
        sx={{ width: "34%", backgroundColor: "rgb(245,245,245)" }}
        className="primary"
        value={range}
        onChange={(e) => setRange(e.target.value)}
        autoWidth
      >
        {ranges.map((range, i) => (
            <MenuItem key={i} value={range.id}>
                {range.label.toUpperCase()}
            </MenuItem>
        ))}
      </Select>
    </Box>
    </Paper >
  );
};

export default DropDownComp;
