import { useEffect } from "react"
import { useFetch } from "react-async";
import { loadOrders } from "../utils/loadOrders"

export const useLoadOrders = ({ setRows }) => {
   const { data, error } = useFetch(
		`${process.env.REACT_APP_MARKET_SERVICE_URL}/orders`,
		{},
		{ json: true }
   );

   useEffect(() => {
		if (data) {
			const response = data as {
				status: number;
				message: string;
				data: any;
			};

			if (response.status === 200) {
				console.log(response.data);
				const orders = response.data;
				setRows(
					orders.map((order: any) => {
						const user = order.user;

						console.log(user.events[0]);
						const createdAt = new Date(order.createdAt);
						const updatedAt = new Date(order.updatedAt);
						const id = order._id;
						const firstName = user.firstName;
						const lastName = user.lastName;
						const email = user.email;
						const phone = user.phone;
						const orderStatus = order.status;

						const shippingAddress = order.shippings.find(
							(shipping: any) => shipping.status === "ACTIVE"
						).shippingAddress;

						const postalCode = shippingAddress?.postalCode;

						const utmParams = user.events[0].utmParams;
						const utmSource =
							utmParams?.utm_source || "bolt-website";
						const utmMedium = utmParams?.utm_medium || "order-form";
						const utmCampaign =
							utmParams?.utm_campaign ||
							"order-bolt-from-website";
						return {
							id,
							createdAt,
							updatedAt,
							firstName,
							lastName,
							email,
							phone,
							orderStatus,
							postalCode,
							shippingAddress,
							...order,
							utmSource,
							utmMedium,
							utmCampaign
						};
					})
				);
			}
		}
   }, [data]);
    
    return { data, error }
}