import { LinearProgress } from "@mui/material";
import {
  GridColDef,
  GridFilterInitialState,
  GridToolbar,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import { useFetch } from "react-async";
import "./styles/input.css";
import "./styles/button.css";
import { AuthContext } from "../../../context/auth";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { CustomDetailPanelToggle } from "./components/CustomDetailPanelToggle";
import { CustomNoRowsOverlay } from "./components/CustomNoRowsOverlay";
import { DetailedView } from "./components/DetailedView";
import { useLoadOrders } from "./hooks/useLoadOrders";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface Data {
  createdAt: number;
  updatedAt: number;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  orderStatus: string;
  postalCode: string;
  shippingAddress: any;
}

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", minWidth: 100, flex: 0.5 },
  {
    field: "createdAt",
    headerName: "Created At",
    minWidth: 150,
    flex: 0.5,
    type: "dateTime",
    valueFormatter: (value: GridValueFormatterParams) =>
      new Date(value.value as Date).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }),
  },
  {
    field: "updatedAt",
    headerName: "Updated At",
    minWidth: 150,
    flex: 0.5,
    type: "dateTime",
    valueFormatter: (value: GridValueFormatterParams) =>
      new Date(value.value as Date).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }),
  },
  {
    field: "utmSource",
    headerName: "UTM Source",
    minWidth: 150,
    flex: 0.5,
  },
  {
    field: "utmMedium",
    headerName: "UTM Medium",
    minWidth: 150,
    flex: 0.5,
  },
  {
    field: "utmCampaign",
    headerName: "UTM Campaign",
    minWidth: 150,
    flex: 0.5,
  },
  {
    field: "fullName",
    headerName: "Full Name",
    valueGetter: (params: GridValueGetterParams) => {
      return `${params.row.firstName} ${params.row.lastName}`;
    },
    minWidth: 150,
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "phone",
    headerName: "Phone",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "orderStatus",
    headerName: "Status",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "postalCode",
    headerName: "Postal Code",
    minWidth: 150,
    flex: 1,
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    headerName: "More",
    renderCell: (params) => (
      <CustomDetailPanelToggle id={params.id} value={params.value} />
    ),
    width: 50,
    flex: 0.3,
  },
];

export const DataGridTable = () => {
  const context: any = useContext(AuthContext);

  const [pageSize, setPageSize] = useState(10);
  let [rows, setRows] = useState<Data[]>([]);

  const { data, error } = useLoadOrders({ setRows });

  if (error) {
    return <div>Error</div>;
  }

  let filter: GridFilterInitialState | undefined;
  if (
    context.user.email === "courier@revos.in" ||
    context.user.email === "taijas@bolt.earth" ||
    context.user.userId === "61a34c81f42ea00007fbc17d"
  ) {
    filter = {
      filterModel: {
        items: [
          {
            columnField: "orderStatus",
            value: "CONFIRMED",
            operatorValue: "equals",
          },
        ],
      },
    };
  }

  return (
    <div
      style={{
        display: "flex",
        height: "90vh",
        backgroundColor: "white",
        borderRadius: "5px",
        padding: "10px",
      }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        components={{
          Toolbar: GridToolbar,
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={!data && !error}
        sortingOrder={["asc", "desc"]}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              postalCode: false,
              utmSource: false,
              utmMedium: false,
              utmCampaign: false,
            },
          },
          sorting: {
            sortModel: [{ field: "createdAt", sort: "asc" }],
          },
          filter,
        }}
        sx={{
          cursor: "pointer",
        }}
        getDetailPanelContent={({ row }) => (
          <DetailedView row={row} setRows={setRows} />
        )}
        getDetailPanelHeight={() => 350}
      />
    </div>
  );
};
