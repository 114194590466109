import React, { useState, useEffect } from "react";
import {
	Button,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Select,
	MenuItem,
	FormControl,
	InputLabel
} from "@material-ui/core";
import Success from "./Success";

interface Props {
	open: boolean;
	handleClose: () => void;
	data: any;
}

const Notify: React.FC<Props> = ({ open, handleClose, data }) => {
	const types = ["KYC Inappropriate", "KYC Pending", "Order Pending"];
	const [typeSelect, setTypeSelect] = useState<any>();
	const [list, setList] = useState({
		phone: [],
		email: []
	});
	const [successDialog, setSuccessDialog] = useState({
		open: false,
		status: 0
	});

	useEffect(() => {
		if (open && data) {
			setList({
				phone: data.phoneList,
				email: data.emailList
			});
		}
		if (!open) {
			setTypeSelect("");
		}
	}, [open]);

	const onSave = () => {
		const phoneData = {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				recepientPhones: list.phone
			})
		};
		fetch(`https://marketing.revos.in/pushStatus/reminder`, phoneData)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				setSuccessDialog({ ...successDialog, status: data.status });
			});
		// const emailData = {
		//   method: "POST",
		//   headers: {
		//     "Content-Type": "application/json",
		//   },
		//   body: JSON.stringify({
		//     recepientEmails: list.email,
		//   }),
		// };
		// fetch(
		//   `https://marketing.revos.in/pushStatus/kycPending/email`,
		//   emailData
		// )
		//   .then((response) => response.json())
		//   .then((data) => {
		//     console.log(data);
		//   });

		handleClose();
	};

	return (
		<>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>
					<Typography
						style={{ fontWeight: "bold", fontSize: "20px" }}
					>
						Notify User{data.emailList.length > 1 ? "s" : ""}
					</Typography>
				</DialogTitle>
				<DialogContent
					style={{
						marginTop: "20px",
						marginBottom: "20px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Typography>
						Select the type for your{" "}
						<span style={{ fontWeight: "bold" }}>Email & SMS</span>
						&nbsp;Notification:
					</Typography>

					<FormControl fullWidth>
						<InputLabel
							style={{
								marginTop: "15px",
								marginLeft: "15px"
							}}
							id='demo-simple-select-label'
						>
							Notification Type
						</InputLabel>
						<Select
							label='Select Notification Type'
							style={{
								height: "50px",
								marginTop: "25px",
								width: "100%",
								display: "flex",
								backgroundColor: "none"
							}}
							variant='outlined'
							className='primary'
							onChange={(e: any) => setTypeSelect(e.target.value)}
						>
							{types.map((type, i) => (
								<MenuItem key={i} value={type}>
									{type}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions style={{ margin: "20px 20px 20px 0" }}>
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						variant='contained'
						color='primary'
						disableElevation
						disabled={!typeSelect}
						onClick={() => {
							setSuccessDialog({ ...successDialog, open: true });
							onSave();
						}}
					>
						Send Notification
					</Button>
				</DialogActions>
			</Dialog>
			{/* <Success
        open={successDialog.open}
        setSuccessDialog={setSuccessDialog}
        status={successDialog.status}
        success={"Notification Sent Succesfully!"}
        error={"Failed To Send Notification"}
      /> */}
		</>
	);
};

export default Notify;
