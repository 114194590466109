import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";

import Paper from "@material-ui/core/Paper";
import GridComp from "./OverviewComponents/GridComp";
interface NewObj {
  id: string;
  name: string;
}
const Overview: React.FC = () => {
  const [companies, setCompanies] = useState([]);
  const FEED_QUERY = gql`
    {
      companyQ {
        getAllCompanies {
          id
          name
        }
      }
    }
  `;

  const { data, refetch } = useQuery(FEED_QUERY);
  useEffect(() => {
    if (data) {
      setCompanies(
        data.companyQ.getAllCompanies.filter((el: any) => {
          // if (el.name === "REVOS") {
          //   return el;
          // }
          if (el.name === "ETO MOTORS") {
            return el;
          }
          if (el.name === "Batt:RE") {
            return el;
          }
          if (el.name === "Zypp electric") {
            return el;
          }
          if (el.name === "Hala mobility") {
            return el;
          }
          if (el.name === "OKINAWA AUTOTECH PVT LTD") {
            return el;
          }
        })
      );
    }
  }, [data]);

  let obj: NewObj = { id: '5cfa443da7b11b00073f9657', name: 'Master' }
  let newArray = [obj].concat(companies)
  console.log(newArray, 'companies data');
  return (
    <Paper
      elevation={3}
      style={{
        padding: 12,
        margin: 0,
        border: "0.2px solid #44305660",
      }}
    >
      <GridComp 
      companies={newArray} 
      />
    </Paper>
  );
};

export default Overview;
// >>>>>>> e7b1d438f08146ee6fee9e3a894ff0ebcf7f9694
