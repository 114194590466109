import React, { useState, useContext } from "react";
import { Paper, Button, Box, Typography } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../../../context/auth";
import xlsx from "xlsx";
import { jsPDF } from "jspdf";
import { StaticTimePicker } from "@mui/lab";

interface Props {
  collectionStats: {
    totalUser: number;
    totalKYC: number;
    totalOrder: number;
    usersUnverified: number;
    usersVerified: number;
    usersOrdered: number;
    ordersInitialised: number;
    ordersPlaced: number;
    kycsPending: number;
    kycsSubmitted: number;
    // kycReminder1sSent: number;
    // kycReminder2sSent: number;
    kycsApproved: number;
    kycsRejected: number;
  };
  statsLoading: boolean;
}

const Counter: React.FC<any> = ({
  skip,
  trackStatus,
  kycReminders,
  dateAfter,
  dateBefore,
  order,
  utmSwitch,
  utmMedium,
  pincode,
  userCity,
  state,
  collectionStats,
  statsLoading,
  purpose,
}) => {
  console.log(
    skip,
    trackStatus,
    kycReminders,
    dateAfter,
    dateBefore,
    order,
    utmSwitch,
    utmMedium,
    pincode,
    userCity,
    state,
    collectionStats,
    statsLoading,
    purpose
  );
  const context: any = useContext(AuthContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  async function Data(url: string) {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  }

  const Download = () => {
    setIsDownloading(true);

    Data(
      `https://marketing.revos.in/downloadAll?first=40&skip=${skip}&trackStatus=${trackStatus}&kycReminders=${
        kycReminders === "ALL REMINDERS" ? "" : kycReminders
      }&bookingsAfter=${dateAfter}&bookingsBefore=${dateBefore}&orderBy=createdAt_${order}&utmSource=${utmSwitch}&utmMedium=${utmMedium}&postalCode=${pincode}&purpose=${
        purpose === "ALL" ? "" : purpose
      }&city=${userCity}&state=${state}`
    ).then(async (data) => {
      const users = data.data.users;
      const orders = data.data.orders;
      const kycs = data.data.kycs;
      const utms = data.data.utms;
      const userComments = data.data.userComments;
      const kycRemarks = data.data.kycRemarks;
      const xlData = await Promise.all(
        users.map(async (user: any) => {
          const orderDoc = orders.find((order: any) => {
            return order.userId === user.userId;
          });

          const kycDoc = kycs.find((kyc: any) => {
            return kyc.userId === user.userId;
          });

          let refundStatus = orderDoc ? orderDoc.refundStatus : null;
          if (
            orderDoc &&
            orderDoc.refundId &&
            refundStatus !== "REFUND_COMPLETE"
          ) {
            console.log(orderDoc.refundId);
            console.log(refundStatus);
            // const response: any = await fetch(
            // 	`https://payments.revos.in/v1/payments/refunds/cashfree/singlerefund`,
            // 	{
            // 		method: "POST",
            // 		headers: {
            // 			"Content-Type": "application/json"
            // 		},
            // 		body: JSON.stringify({
            // 			refundId: orderDoc.refundId
            // 		})
            // 	}
            // );
            // const jsonResponse = await response.json();
            // console.log(jsonResponse);
            // refundStatus =
            // 	jsonResponse.details.orderDetails.refund[0]
            // 		.processed === "YES"
            // 		? "REFUND_COMPLETE"
            // 		: orderDoc.refundStatus;
          }

          const order = {
            orderId: orderDoc ? orderDoc?.orderId : null,
            trackingId: orderDoc ? orderDoc?.trackingId : null,
            orderStatus: orderDoc ? orderDoc?.orderStatus : null,
            amount: orderDoc ? orderDoc?.amount : null,
            currency: orderDoc ? orderDoc?.currency : null,
            refundId: orderDoc ? orderDoc?.refundId : null,
            refundStatus,
          };

          const kyc = {
            kycId: kycDoc?.kycId,
            kycStatus: kycDoc?.kycStatus,
            pictureUrls: kycDoc?.pictureUrls,
            purpose: kycDoc?.purpose,
            haveEv: kycDoc?.haveEv,
            kycRemarks: JSON.stringify(
              kycRemarks
                .filter((kycRemark: any) => {
                  return kycRemark.userId === user.userId;
                })
                .sort((a: any, b: any) => {
                  return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  );
                })
                .map((kycRemark: any) => {
                  return `${new Date(kycRemark.createdAt).toLocaleString()} - ${
                    kycRemark.remark
                  }`;
                })
            ),
          };

          const utmDoc = utms.find((utm: any) => {
            return utm.userId === user.userId;
          });

          return {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            address: user.address,
            landmark: user.landmark,
            city: user.city,
            state: user.state,
            postalCode: user.postalCode,
            company: user.company,
            latitude: user.latitude,
            longitude: user.longitude,
            trackStatus: user.trackStatus,
            kycReminders: user.kycReminders,
            inappropriateStrikes: user.inappropriateStrikes
              ? user.inappropriateStrikes
              : 0,
            utmSource: utmDoc?.utmSource ? utmDoc?.utmSource : "NA",
            utmMedium: utmDoc?.utmMedium ? utmDoc?.utmMedium : "NA",
            utmCampaign: utmDoc?.utmCampaign ? utmDoc?.utmCampaign : "NA",
            userComments: JSON.stringify(
              userComments
                .filter((comment: any) => {
                  return comment.userId === user.userId;
                })
                .sort((a: any, b: any) => {
                  return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  );
                })
                .map((comment: any) => {
                  return `${new Date(comment.createdAt).toLocaleString()} - ${
                    comment.comment
                  }`;
                })
            ),
            shippingId: user.shippingId ? user.shippingId : "NA",
            shippingProvider: user.shippingProvider
              ? user.shippingProvider
              : "NA",
            shippingDate: user.shippingDate ? user.shippingDate : "NA",
            qrCode: user.qrCode ? user.qrCode : "NA",
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            ...order,
            ...kyc,
          };
        })
      );

      console.log(xlData);

      const ws = xlsx.utils.json_to_sheet(xlData);
      const wb = xlsx.utils.book_new();

      xlsx.utils.book_append_sheet(wb, ws, "rupee-campaign");

      xlsx.writeFile(
        wb,
        `rupee_campaign_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.xlsx`
      );

      // const fileStream = fs.createReadStream('rupee-campaign.xlsx');
      // let a = document.createElement("a");
      // a.href = window.URL.createObjectURL(data);
      // a.download = "RupeeCampaignAllUsers.xlsx";
      // a.click();
      if (xlData) {
        setIsDownloading(false);
      }
      // console.log(data);
    });
  };

  const DownloadReport = () => {
    setIsDownloadingReport(true);
    Data(`https://marketing.revos.in/getReport`)
      .then(async (data) => {
        const numberOfKycApproved = data.data.numberOfKycApproved;
        const numberOfKycRejected = data.data.numberOfKycRejected;
        const numberOfKycInappropiate = data.data.numberOfKycInappropiate;
        const numberOfDispatched = data.data.numberOfDispatched;
        const numberOfCancelled = data.data.numberOfCancelled;
        const numberOfRefundInitiated = data.data.numberOfRefundInitiated;
        const doc = new jsPDF();

        doc.text(`Number of KYC Approved: ${numberOfKycApproved}`, 10, 10);
        doc.text(`Number of KYC Rejected: ${numberOfKycRejected}`, 10, 20);
        doc.text(
          `Number of KYC Inappropiate: ${numberOfKycInappropiate}`,
          10,
          30
        );
        doc.text(`Number of Dispatched: ${numberOfDispatched}`, 10, 40);
        doc.text(`Number of Cancelled: ${numberOfCancelled}`, 10, 50);
        doc.text(
          `Number of Refund Initiated: ${numberOfRefundInitiated}`,
          10,
          60
        );
        doc.save("report.pdf");
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });

    setIsDownloadingReport(false);
  };

  const loading = (
    <Box pt="5px">
      <CircularProgress style={{ color: "#201529" }} size={25} />
    </Box>
  );
  return (
    <Paper
      style={{
        width: "12%",
        padding: 12,
        marginRight: 15,
      }}
    >
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          width="100%"
          height="5%"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            fontSize: "1.1rem",
            fontWeight: "bold",
            color: "#443056",
          }}
        >
          ₹1 Campaign
        </Box>
        {context.user.email === "marketing@revos.in" ||
        context.user.email === "taijas@bolt.earth" ||
        context.user.userId === "618bd59eeb097a000885a169" ? (
          <>
            <Box
              width="100%"
              height="10%"
              style={{
                background: "rgba(32,21,41,0.55)",
                marginTop: "8px",
                borderRadius: "0 8px 8px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                All Users
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                {statsLoading ? loading : collectionStats.totalUsers || 0}
              </Typography>
            </Box>
            <Box
              width="92%"
              height="10%"
              style={{
                background: "rgba(32,21,41,0.55)",
                marginTop: "10px",
                borderRadius: "0 8px 8px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                Users Verified
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                {statsLoading
                  ? loading
                  : collectionStats.usersVerified +
                      collectionStats.usersOrdered +
                      collectionStats.ordersInitialised +
                      collectionStats.ordersPlaced +
                      collectionStats.kycsPending +
                      collectionStats.kycsSubmitted +
                      collectionStats.kycsInappropriate +
                      collectionStats.kycsApproved +
                      collectionStats.kycsRejected +
                      collectionStats.kycsDuplicate +
                      collectionStats.kycsResubmitted +
                      collectionStats.kycsShortlisted +
                      collectionStats.orderDispatched +
                      collectionStats.orderDelivered +
                      collectionStats.chargersInstalled +
                      collectionStats.chargersInitialised +
                      collectionStats.orderCancelled +
                      collectionStats.refundInitiated || 0}
              </Typography>
            </Box>

            <Box
              width="84%"
              height="10%"
              style={{
                background: "rgba(32,21,41,0.65)",
                marginTop: "10px",
                borderRadius: "0 8px 8px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                Order Initialised
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                {statsLoading
                  ? loading
                  : collectionStats.ordersInitialised +
                      collectionStats.ordersPlaced +
                      collectionStats.kycsPending +
                      collectionStats.kycsSubmitted +
                      collectionStats.kycsInappropriate +
                      collectionStats.kycsApproved +
                      collectionStats.kycsRejected +
                      collectionStats.kycsDuplicate +
                      collectionStats.kycsResubmitted +
                      collectionStats.kycsShortlisted +
                      collectionStats.orderDispatched +
                      collectionStats.orderDelivered +
                      collectionStats.chargersInstalled +
                      collectionStats.chargersInitialised +
                      collectionStats.orderCancelled +
                      collectionStats.refundInitiated || 0}
              </Typography>
            </Box>

            <Box
              width="76%"
              height="10%"
              style={{
                background: "rgba(32,21,41,0.7)",
                marginTop: "10px",
                borderRadius: "0 8px 8px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                Order Placed
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                {statsLoading
                  ? loading
                  : collectionStats.ordersPlaced +
                      collectionStats.kycsPending +
                      collectionStats.kycsSubmitted +
                      collectionStats.kycsInappropriate +
                      collectionStats.kycsApproved +
                      collectionStats.kycsRejected +
                      collectionStats.kycsDuplicate +
                      collectionStats.kycsResubmitted +
                      collectionStats.kycsShortlisted +
                      collectionStats.orderDispatched +
                      collectionStats.orderDelivered +
                      collectionStats.chargersInstalled +
                      collectionStats.chargersInitialised +
                      collectionStats.orderCancelled +
                      collectionStats.refundInitiated || 0}
              </Typography>
            </Box>

            <Box
              width="68%"
              height="10%"
              style={{
                background: "rgba(32,21,41,0.85)",
                marginTop: "10px",
                borderRadius: "0 8px 8px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                KYC Submitted
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                {statsLoading
                  ? loading
                  : collectionStats.kycsSubmitted +
                      collectionStats.kycsInappropriate +
                      collectionStats.kycsApproved +
                      collectionStats.kycsRejected +
                      collectionStats.kycsDuplicate +
                      collectionStats.kycsResubmitted +
                      collectionStats.kycsShortlisted +
                      collectionStats.orderDispatched +
                      collectionStats.orderDelivered +
                      collectionStats.chargersInstalled +
                      collectionStats.chargersInitialised || 0}
              </Typography>
            </Box>

            <Box
              width="60%"
              height="10%"
              style={{
                background: "rgba(32,21,41,0.9)",
                marginTop: "10px",
                borderRadius: "0 8px 8px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                KYC Approved
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                {statsLoading
                  ? loading
                  : collectionStats.kycsApproved +
                      collectionStats.orderDispatched +
                      collectionStats.orderDelivered +
                      collectionStats.chargersInstalled +
                      collectionStats.chargersInitialised || 0}
              </Typography>
            </Box>
            <Box
              width="100%"
              height="10%"
              style={{ marginTop: "10px", marginBottom: "8px" }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{
                  fontSize: "13px",
                  height: "60px",
                  width: "100%",
                  marginBottom: "8px",
                }}
                onClick={DownloadReport}
              >
                <Box>
                  {isDownloadingReport ? "Downloading..." : "Download Report"}
                  <Typography style={{ fontSize: "8px" }}>
                    {isDownloadingReport ? "Please Wait" : ""}
                  </Typography>
                </Box>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  fontSize: "13px",
                  height: "60px",
                  width: "100%",
                  marginTop: "8px",
                }}
                onClick={Download}
              >
                <Box>
                  {isDownloading ? "Downloading..." : "Download Excel"}
                  <Typography style={{ fontSize: "8px" }}>
                    {isDownloading ? "Please Wait" : ""}
                  </Typography>
                </Box>
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box
              width="100%"
              height="18%"
              style={{
                background: "rgba(32,21,41,0.7)",
                marginTop: "10px",
                borderRadius: "0 8px 8px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                Order Placed
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                {statsLoading
                  ? loading
                  : collectionStats.ordersPlaced +
                      collectionStats.kycsPending +
                      collectionStats.kycsSubmitted +
                      collectionStats.kycsInappropriate +
                      collectionStats.kycsApproved +
                      collectionStats.kycsRejected +
                      collectionStats.kycsDuplicate +
                      collectionStats.kycsResubmitted +
                      collectionStats.kycsShortlisted +
                      collectionStats.orderDispatched +
                      collectionStats.orderDelivered +
                      collectionStats.chargersInstalled +
                      collectionStats.chargersInitialised +
                      collectionStats.orderCancelled +
                      collectionStats.refundInitiated || 0}
              </Typography>
            </Box>

            <Box
              width="90%"
              height="18%"
              style={{
                background: "rgba(32,21,41,0.85)",
                marginTop: "10px",
                borderRadius: "0 8px 8px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                KYC Submitted
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                {statsLoading
                  ? loading
                  : collectionStats.kycsSubmitted +
                      collectionStats.kycsInappropriate +
                      collectionStats.kycsApproved +
                      collectionStats.kycsRejected +
                      collectionStats.kycsDuplicate +
                      collectionStats.kycsResubmitted +
                      collectionStats.kycsShortlisted +
                      collectionStats.orderDispatched +
                      collectionStats.orderDelivered +
                      collectionStats.chargersInstalled +
                      collectionStats.chargersInitialised || 0}
              </Typography>
            </Box>

            <Box
              width="80%"
              height="13%"
              style={{
                background: "rgba(32,21,41,0.9)",
                marginTop: "10px",
                borderRadius: "0 8px 8px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "11px",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                KYC Approved
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "8px",
                  color: "white",
                }}
              >
                {statsLoading
                  ? loading
                  : collectionStats.kycsApproved +
                      collectionStats.orderDispatched +
                      collectionStats.orderDelivered +
                      collectionStats.chargersInstalled +
                      collectionStats.chargersInitialised || 0}
              </Typography>
            </Box>
            <Box
              width="100%"
              height="10%"
              style={{ marginTop: "10px", marginBottom: "8px" }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{
                  fontSize: "13px",
                  height: "60px",
                  width: "100%",
                  marginBottom: "8px",
                }}
                onClick={DownloadReport}
              >
                <Box>
                  {isDownloadingReport ? "Downloading..." : "Download Report"}
                  <Typography style={{ fontSize: "8px" }}>
                    {isDownloadingReport ? "Please Wait" : ""}
                  </Typography>
                </Box>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  fontSize: "13px",
                  height: "60px",
                  width: "100%",
                  marginTop: "8px",
                }}
                onClick={Download}
              >
                <Box>
                  {isDownloading ? "Downloading..." : "Download Excel"}
                  <Typography style={{ fontSize: "8px" }}>
                    {isDownloading ? "Please Wait" : ""}
                  </Typography>
                </Box>
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default Counter;
