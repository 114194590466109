import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Paper, Box, useTheme, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from "@material-ui/core";
import moment from 'moment'
const Request: React.FC = () => {
    const theme = useTheme();

    const [checkBoxState, setCheckBoxState] = useState({ app: true, total: true, dashboard: true })

    const [appRequests, setAppRequestData] = useState<Array<any>>([])
    const [totalRequests, setTotalRequestData] = useState<Array<any>>([])
    const [dashboardRequests, setDashboardRequestData] = useState<Array<any>>([])

    useEffect(() => {
        totalRequestData()
        companyRequestsData()
        appRequestData()
    }, [])

    const totalRequestData = async () => {
        try {
            let url = 'https://datafeed.dev.revos.in/service/bolt/stats/totalRequestCount'
            let res = await fetch(url, {
                headers: { token: '1234' },
                method: "GET",
            })
            let response = await res.json() as Array<any>;
            setTotalRequestData(response as Array<any>)
            console.log(totalRequests.map(obj => obj.count), "overall data")
        }
        catch (e) {
            return e
         }
    }

    const companyRequestsData = async () => {
        try {
            let url = 'https://datafeed.dev.revos.in/service/bolt/stats/companyRequestCount'
            let res = await fetch(url, {
                headers: { token: '1234' },
                method: "GET",
            })
            let response = await res.json() as Array<any>;
            setDashboardRequestData(response as Array<any>)
            console.log(dashboardRequests.map(obj => obj.count), "dashboard data")
        }
        catch (e) {
            return e
         }
    }

    const appRequestData = async () => {
        try {
            let url = 'https://datafeed.dev.revos.in/service/bolt/stats/appRequestCount'
            let res = await fetch(url, {
                headers: { token: '1234' },
                method: "GET",
            })
            let response = await res.json() as Array<any>;
            setAppRequestData(response as Array<any>)
            console.log(appRequests, "app data")
        }
        catch (e) {
            return e
         }
    }

    const onCheckBoxChange = (type: string) => {
        switch (type) {
            case 'total':
                return setCheckBoxState((prev) => ({ ...prev, total: !checkBoxState.total }))
            case 'app':
                return setCheckBoxState((prev) => ({ ...prev, app: !checkBoxState.app }))
            case 'dashboard':
                return setCheckBoxState((prev) => ({ ...prev, dashboard: !checkBoxState.dashboard }))

        }
    }

    return (
        <Paper
            sx={{
                p: 3,
                pb: 4,
                gridColumn: "span 5",
                height: 314,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box mb={7} display="flex" alignItems={"center"} >
                <Typography variant="h6">Requests</Typography>
                {/* <h2>Requests</h2> */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        flexDirection: "row",
                        width: "500px",
                    }}
                >
                    <FormControlLabel
                        label="Total"
                        control={
                            <Checkbox checked={checkBoxState.total} onChange={() => { onCheckBoxChange('total') }} />}
                    />
                    <FormControlLabel
                        label="App"
                        control={
                            <Checkbox checked={checkBoxState.app} onChange={() => { onCheckBoxChange('app') }} />}
                    />
                    <FormControlLabel
                        label="Dashboard"
                        control={
                            <Checkbox checked={checkBoxState.dashboard} onChange={() => { onCheckBoxChange('dashboard') }} />}
                    />
                </div>
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    width: 1,
                    minHeight: 0,
                }}
            >
                <Line
                    data={(canvas) => {
                        let color = "#538ADC";
                        let labels = appRequests.map(obj => moment(obj.date).format('MMM DD'))
                        console.log(appRequests, totalRequests, dashboardRequests, "data for graphs")
                        return {
                            labels,
                            datasets: [
                                {
                                    yAxisID: "yAxis",
                                    label: "Total",
                                    fill: false,
                                    data: totalRequests.map(obj => parseInt(obj.count)),
                                    borderColor: color,
                                    borderWidth: 2,
                                    hidden: !checkBoxState.total
                                },
                                {
                                    yAxisID: "yAxis2",
                                    label: "App",
                                    fill: false,
                                    data: appRequests.map(obj => parseInt(obj.count)),
                                    borderColor: '#90EE90',
                                    borderWidth: 2,
                                    hidden: !checkBoxState.app
                                },
                                {
                                    yAxisID: "yAxis1",
                                    label: "Dashboard",
                                    fill: false,
                                    data: dashboardRequests.map((obj) => obj.count),
                                    borderColor: 'red',
                                    borderWidth: 2,
                                    hidden: !checkBoxState.dashboard
                                },
                            ],
                        };
                    }}
                    options={{
                        scales: {
                            xAxis: {
                                // type: 'time',
                                grid: {
                                    display: false,
                                    tickWidth: 0,
                                    tickLength: 16,
                                    drawBorder: false,
                                },
                                ticks: {
                                    color: theme.palette.text.secondary,
                                },
                            },
                            yAxis: {
                                // stacked: true,
                                // scaleLabel: {display:true},
                                display: true,
                                title: {
                                    display: true,
                                    text: "Total Requests",
                                    padding: {
                                        top: 0,
                                        bottom: 8,
                                    },
                                    color: 'grey',
                                    font: {
                                        weight: "500",
                                        size: 12,
                                    },
                                },
                                ticks: {
                                    color: theme.palette.text.secondary,
                                },
                                suggestedMin: 15,
                                suggestedMax: 85,
                                grid: {
                                    borderDash: [10],
                                    tickWidth: 0,
                                    tickLength: 16,
                                    drawBorder: false,
                                },
                            },
                            yAxis1: {
                                // stacked: true,
                                // scaleLabel: {display:true},
                                display: false,
                                title: {
                                    display: false,
                                    text: "App Requests",
                                    padding: {
                                        top: 0,
                                        bottom: 8,
                                    },
                                    color: 'grey',
                                    font: {
                                        weight: "500",
                                        size: 12,
                                    },
                                },
                                ticks: {
                                    color: theme.palette.text.secondary,
                                },
                                suggestedMin: 15,
                                suggestedMax: 85,
                                grid: {
                                    borderDash: [10],
                                    tickWidth: 0,
                                    tickLength: 16,
                                    drawBorder: false,
                                },
                            },
                            yAxis2: {
                                // stacked: true,
                                // scaleLabel: {display:true},
                                display: false,
                                title: {
                                    display: false,
                                    text: "Dashboard Requests",
                                    padding: {
                                        top: 0,
                                        bottom: 8,
                                    },
                                    color: 'grey',
                                    font: {
                                        weight: "500",
                                        size: 12,
                                    },
                                },
                                ticks: {
                                    color: theme.palette.text.secondary,
                                },
                                suggestedMin: 15,
                                suggestedMax: 85,
                                grid: {
                                    borderDash: [10],
                                    tickWidth: 0,
                                    tickLength: 16,
                                    drawBorder: false,
                                },
                            },
                        },

                        responsive: true,
                        maintainAspectRatio: false,
                        
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                caretSize: 0,
                                mode: "index",
                                intersect: false,
                                yAlign: "center",
                                displayColors: false,
                                caretPadding: 16,
                                titleFont: {
                                    weight: "400",
                                },
                                bodyFont: {
                                    weight: "500",
                                },
                            },
                        },
                        interaction: {
                            mode: "index",
                            intersect: false,
                        },
                    }}
                />
            </Box>
        </Paper>
    )
}

export default Request