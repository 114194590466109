import { FC, useState } from "react";
import { dispatchOrder } from "../utils/dispatchOrder";
import { downloadInvoice } from "../utils/downloadInvoice";
import { loadOrders } from "../utils/loadOrders";

export const ShippingForm: FC<{ row: any, setRows: any }> = ({ row, setRows }) => {
	const [loading, setLoading] = useState(false);

	const [provider, setProvider] = useState<string>("");
	const [shipmentId, setShipmentId] = useState<string>("");
	const [itemId, setItemId] = useState<string>("");
	const [dispatchDate, setDispatchDate] = useState<Date>(new Date());

	// const { data, error } = useFetch(
	// 	`${process.env.REACT_APP_MARKET_SERVICE_URL}/orders`,
	// 	{},
	// 	{ json: true }
	// );

	// useEffect(() => {
	// 	console.log("Here")

	// }, [data]);

	return (
		<div>
			<h1
				style={{
					fontSize: "14px",
					fontWeight: "normal"
				}}
			>
				Dispatch Form:
			</h1>
			<form
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "10px",
					width: "100%"
				}}
				onSubmit={async (e) => {
					e.preventDefault();
					setLoading(true);
					// pdfMake.createPdf(generateInvoice(row)).open();
					await dispatchOrder({
						orderId: row.id,
						provider,
						shipmentId,
						itemId,
						dispatchDate
					});
					setRows(await loadOrders());
					setLoading(false);
				}}
			>
				<label className='input-container'>
					<input
						className='input'
						type='text'
						placeholder=' '
						onChange={(e) => setProvider(e.target.value)}
					/>
					<span className='input-label'>
						Shipping Provider (Optional)
					</span>
				</label>
				<label className='input-container'>
					<input
						required
						className='input'
						type='text'
						placeholder=' '
						onChange={(e) => setShipmentId(e.target.value)}
					/>
					<span className='input-label'>Shipment Id</span>
				</label>
				<label className='input-container'>
					<input
						required
						className='input'
						type='text'
						placeholder=' '
						onChange={(e) => setItemId(e.target.value)}
					/>
					<span className='input-label'>BOLT UID</span>
				</label>
				<label className='input-container'>
					<input
						required
						className='input'
						type='date'
						placeholder=' '
						value={dispatchDate.toISOString().split("T")[0]}
						onChange={(e) =>
							setDispatchDate(new Date(e.target.value))
						}
					/>
					<span className='input-label'>Dispatch Date</span>
				</label>
				<button className='btn btn-primary' type='submit'>
					{loading ? "Loading..." : "Save Shipping Data"}
				</button>
			</form>
			<div style={{ marginTop: "5px" }}>
				<button
					className='btn btn-primary'
					onClick={async () => {
						await downloadInvoice(row.invoice._id);
					}}
				>
					Download Invoice
				</button>
			</div>
		</div>
	);
};
