import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Input,
  Dialog,
  NativeSelect,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import {
  FormatColorReset,
  KeyboardArrowDown,
  TramRounded,
} from "@mui/icons-material";
import {
  DataGrid,
  GridSelectionModel,
  GridSortModel,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import NotifyUser from "./NotifyUser";
import TrackKYC from "./TrackKYC";
import Counter from "./Counter";
import InfoDrawer from "./InfoDrawer";
import Backward from "@mui/icons-material/ArrowBackIosNewRounded";
import Forward from "@mui/icons-material/ArrowForwardIosRounded";
import InfoIcon from "@mui/icons-material/Info";
import Review from "@mui/icons-material/Image";
import SearchIcon from "@mui/icons-material/Search";
import Map from "./Map";
import DatePicker from "./DatePicker";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import moment from "moment";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../../../context/auth";
import { SwipeableDrawer } from "@material-ui/core";
import { DateRange } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .column-header": {
      fontSize: "12px",
    },
  },
  input1: {
    height: "15px",
    fontSize: "12px",
  },
}));

const Devices = () => {
  const context: any = useContext(AuthContext);
  // 1) State
  // Data
  const [collectionStats, setCollectionStats] = useState<any>();

  // Component states
  const [emailList, setEmailList] = useState<any>();
  const [phoneList, setPhoneList] = useState<any>();
  const [notifyDialog, setNotifyDialog] = useState({
    open: false,
    data: {
      emailList: [],
      phoneList: [],
    },
  });
  const [trackDialog, setTrackDialog] = useState({
    open: false,
    data: {},
  });
  const [infoDrawer, setInfoDrawer] = useState({
    open: false,
    data: {},
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const [purpose, setPurpose] = useState("ALL");
  const purposeFilters = ["ALL", "Private", "Public", "Private, Public"];
  // Table
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [onPage, setOnPage] = useState(1);
  const [search, setSearch] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  let selectedIDs: any;

  let trackStatusFilter = ["ORDER_DISPATCHED"];
  if (
    context.user.email === "marketing@revos.in" ||
    context.user.email === "taijas@bolt.earth" ||
    context.user.userId === "618bd59eeb097a000885a169"
  ) {
    trackStatusFilter = [
      "ALL STATUS",
      "USER_UNVERIFIED",
      "USER_VERIFIED",
      "ORDER_INITIALISED",
      "ORDER_PLACED", // Operations
      "KYC_PENDING", // Operations
      "KYC_SUBMITTED", // Operations
      "KYC_INAPPROPRIATE", // Operations
      "KYC_APPROVED", // Operations, Supply Chain
      "KYC_REJECTED", // Operations
      "KYC_DUPLICATE",
      "KYC_RESUBMITTED",
      "KYC_SHORTLISTED",
      "ORDER_DISPATCHED", // Operations, Supply Chain
      "ORDER_DELIVERED",
      "CHARGER_INSTALLED",
      "CHARGER_INITIALISED",
      "ORDER_CANCELLED", // Operations, Supply Chain
      "REFUND_INITIATED", // Operations, Supply Chain
    ];
  }
  if (
    context.user.email === "operations@revos.in" ||
    context.user.email === "taijas@bolt.earth" ||
    context.user.userId === "6198f5cf30fb8c0007aed17a"
  ) {
    trackStatusFilter = [
      "ALL STATUS",
      "USER_UNVERIFIED",
      "USER_VERIFIED",
      "ORDER_INITIALISED",
      "ORDER_PLACED", // Operations
      "KYC_PENDING", // Operations
      "KYC_SUBMITTED", // Operations
      "KYC_INAPPROPRIATE", // Operations
      "KYC_APPROVED", // Operations, Supply Chain
      "KYC_REJECTED", // Operations
      "KYC_DUPLICATE",
      "KYC_RESUBMITTED",
      "KYC_SHORTLISTED",
      "ORDER_DISPATCHED", // Operations, Supply Chain
      "ORDER_DELIVERED",
      "CHARGER_INSTALLED",
      "CHARGER_INITIALISED",
      "ORDER_CANCELLED", // Operations
      "REFUND_INITIATED", // Operations
    ];
  }
  if (
    context.user.email === "courier@revos.in" ||
    context.user.email === "taijas@bolt.earth" ||
    context.user.userId === "61a34c81f42ea00007fbc17d"
  ) {
    trackStatusFilter = [
      "KYC_APPROVED", // Operations, Supply Chain
      "ORDER_DISPATCHED", // Operations, Supply Chain
      "ORDER_DELIVERED",
      "CHARGER_INSTALLED",
      "CHARGER_INITIALISED",
      "ORDER_CANCELLED",
      "REFUND_INITIATED",
    ];
  }

  const utm = [
    "ALL SOURCES",
    "Pyxis_Google",
    "Pyxis_Facebook",
    "DV360",
    "Pyxis_discovery",
    "Pyxis_native",
    "Pyxis_conversion",
    "Pyxis_smartdisplay",
    "Pyxis_engagement",
    "Pyxis_postpromotion",
    "Pyxis_YouTubeTFA",
    "Pyxis_YouTubeTFA_Sales",
  ];

  const reminders = [
    "ALL REMINDERS",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
  ];
  // API

  // reminders filter

  // const [reminders, setReminders] = useState(0);

  // status filter
  const [trackStatus, setTrackStatus] = useState(
    trackStatusFilter[0] === "ALL STATUS" ? "" : trackStatusFilter[0]
  );

  // kycReminders filter
  const [kycReminders, setKycReminders] = useState("ALL REMINDERS");

  // utm filter
  const [utmFilter, setUTMFilter] = useState(utm[0]);
  const [utmSwitch, setUTMSwitch] = useState("");
  const [utmMedium, setUTMMedium] = useState("");

  // misc
  const [filterCount, setFilterCount] = useState(0);
  const [order, setOrder] = useState("createdAt_ASC");
  const orderOptions = [
    { label: "Created At (Asc)", value: "createdAt_ASC" },
    { label: "Updated At (Asc)", value: "updatedAt_ASC" },
    { label: "Created At (Desc)", value: "createdAt_DESC" },
    { label: "Updated At (Desc)", value: "updatedAt_DESC" },
  ];
  const [skip, setSkip] = useState(0);

  // Date
  const [range, setRange] = useState<any>(["", ""]);
  const [dateAfter, setDateAfter] = useState("");
  const [dateBefore, setDateBefore] = useState("");

  // Search
  const [pincode, setPincode] = useState("");

  // 2) API Functions

  // Get universal function
  async function getData(url: string) {
    const response = await fetch(url, {
      method: "GET",
    });
    return response.json();
  }

  // Get list of entries
  const getDetails = async () => {
    const data = await getData(
      `https://marketing.revos.in/getAll?first=40&skip=${skip}&trackStatus=${trackStatus}&kycReminders=${
        kycReminders === "ALL REMINDERS" ? "" : kycReminders
      }&bookingsAfter=${dateAfter}&bookingsBefore=${dateBefore}&orderBy=${order}&utmSource=${utmSwitch}&utmMedium=${utmMedium}&postalCode=${pincode}&purpose=${
        purpose === "ALL" ? "" : purpose
      }
			&city=${city}&state=${state}`
    );

    if (data.status === 200) {
      setRows(
        data.data.dataCollection === undefined ? [] : data.data.dataCollection
      );
      setFilterCount(data.data.stats.totalUsers);
      setLoading(false);
      if (data.data.stats.totalUsers === 0) alert("Invalid Input");
    }
    if (data.status !== 200) {
      if (data.message !== undefined) alert(data.message);
      setRows([]);
      setLoading(false);
    }
  };

  // Get overall stats
  const getStats = () => {
    getData(
      `https://marketing.revos.in/getStats?bookingsAfter=${dateAfter}&bookingsBefore=${dateBefore}&utmSource=${utmSwitch}&utmMedium=${utmMedium}&postalCode=${pincode}&kycReminders=${
        kycReminders === "ALL REMINDERS" ? "" : kycReminders
      }&purpose=${purpose === "ALL" ? "" : purpose}&city=${city}&state=${state}`
    ).then((data) => {
      if (data) {
        setCollectionStats(data.data);
        setStatsLoading(false);
      }
    });
  };

  // 3) Action Functions

  // Search Call
  const onSearch = () => {
    const phoneData = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        phone: search[0] === "+" ? search : "",
        email: search.includes("@") ? search : "",
        orderId: search[0] === "+" || search.includes("@") ? "" : search,
      },
    };
    fetch(`https://marketing.revos.in/user/getDetails`, phoneData)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setRows([data.data]);
        }
        if (data.status !== 200) {
          alert("Invalid Entry, Try Again");
        }
      });
    setSearch("");
  };

  // Pincode City & State Call
  const handleFunc = async () => {
    setRows([]);
    setStatsLoading(true);
    setLoading(true);
    getStats();
    await getDetails();
  };
  // City Call
  // const handleCity = () => {
  // 	setRows([]);
  // 	setStatsLoading(true);
  // 	setLoading(true);
  // 	getStats();
  // 	getDetails
  // }
  // 4) Lifecycle Functions

  // Switch for trackStatus Filter
  useEffect(() => {
    if (rows) {
      setTrackStatus(trackStatus);
      setSkip(0);
      setOnPage(1);
    }
  }, [trackStatus]);

  // switch for utmFilter
  useEffect(() => {
    if (rows) {
      console.log("utmFilter", utmFilter);
      switch (utmFilter) {
        case "ALL SOURCES":
          setUTMSwitch("");
          setUTMMedium("");
          setSkip(0);
          setOnPage(1);
          break;
        case "Pyxis_Google":
          setUTMSwitch("Pyxis_Google");
          setUTMMedium("Search");
          setSkip(0);
          setOnPage(1);
          break;
        case "Pyxis_Facebook":
          setUTMSwitch("Pyxis_Facebook");
          setUTMMedium("Social");
          setSkip(0);
          setOnPage(1);
          break;
        case "DV360":
          setUTMSwitch("DV360");
          setUTMMedium("Aiquire");
          setSkip(0);
          setOnPage(1);
          break;
        case "Pyxis_discovery":
          setUTMSwitch("Pyxis_discovery");
          setUTMMedium("GDN");
          setSkip(0);
          setOnPage(1);
          break;
        case "Pyxis_conversion":
          setUTMSwitch("Pyxis_conversion");
          setUTMMedium("Facebook_cpa");
          setSkip(0);
          setOnPage(1);
          break;
        case "Pyxis_native":
          setUTMSwitch("Pyxis_native");
          setSkip(0);
          setOnPage(1);
          break;
        case "Pyxis_engagement":
          setUTMSwitch("Pyxis_engagement");
          setUTMMedium("Facebook_cpe");
          setSkip(0);
          setOnPage(1);
          break;
        case "Pyxis_postpromotion":
          setUTMSwitch("Pyxis_postpromotion");
          setUTMMedium("Facebook_cpe");
          setSkip(0);
          setOnPage(1);
          break;
        case "Pyxis_smartdisplay":
          setUTMSwitch("Pyxis_smartdisplay");
          setSkip(0);
          setOnPage(1);
          break;
        case "Pyxis_YouTubeTFA":
          setUTMSwitch("Pyxis_YouTubeTFA");
          setUTMMedium("Video");
          setSkip(0);
          setOnPage(1);
          break;
        case "Pyxis_YouTubeTFA_Sales":
          setUTMSwitch("Pyxis_YouTubeTFA_Sales");
          setUTMMedium("Video");
          setSkip(0);
          setOnPage(1);
          break;
      }
    }
  }, [utmFilter]);

  useEffect(() => {
    setRows([]);
    setStatsLoading(true);
    setLoading(true);
    getStats();
    getDetails();
  }, [
    skip,
    trackStatus,
    utmSwitch,
    dateAfter,
    dateBefore,
    kycReminders,
    purpose,
    order,
  ]);

  const classes = useStyles();

  return (
    <Box style={{ display: "flex", height: "88vh" }}>
      <Counter
        skip={skip}
        // apiSwitch={apiSwitch}
        trackStatus={trackStatus}
        kycReminders={kycReminders}
        dateAfter={dateAfter}
        dateBefore={dateBefore}
        order={order}
        utmSwitch={utmSwitch}
        utmMedium={utmMedium}
        pincode={pincode}
        userCity={city}
        state={state}
        statsLoading={statsLoading}
        collectionStats={collectionStats}
        purpose={purpose}
      />
      <Box display="flex" flexDirection="column" width="88%">
        {/* 1) Filters + Search */}
        <Paper
          style={{
            height: "9%",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <Button
            onClick={() => setIsOpenDialog(true)}
            variant="outlined"
            style={{
              marginLeft: "8px",
              marginBottom: "8px",
              marginTop: "8px",
              boxShadow: "none",
            }}
          >
            Filters
          </Button>
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={isOpenDialog}
            onClose={() => {
              setIsOpenDialog(false);
            }}
          >
            <Box
              padding="10px"
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-around"
              alignContent="space-around"
              height="200px"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="200px"
                minHeight="40px"
              >
                <Select
                  style={{
                    height: "36px",
                    width: "200px",
                    outline: "none",
                    fontSize: "12px",
                    letterSpacing: "0.05rem",
                  }}
                  variant="outlined"
                  className="primary"
                  value={trackStatus === "" ? "ALL STATUS" : trackStatus}
                  placeholder="All"
                  onChange={(e: any) =>
                    setTrackStatus(
                      e.target.value === "ALL STATUS" ? "" : e.target.value
                    )
                  }
                  // IconComponent={KeyboardArrowDown}
                >
                  {trackStatusFilter.map((filter, i) => (
                    <MenuItem key={i} value={filter}>
                      {filter}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="200px"
                minHeight="40px"
              >
                <Select
                  style={{
                    height: "36px",
                    width: "200px",
                    outline: "none",
                    fontSize: "12px",
                    letterSpacing: "0.05rem",
                  }}
                  variant="outlined"
                  className="primary"
                  value={kycReminders}
                  // label='Kyc Reminders'
                  onChange={(e: any) => {
                    return setKycReminders(e.target.value);
                  }}
                  // IconComponent={KeyboardArrowDown}
                >
                  {reminders.map((filter, i) => (
                    <MenuItem key={i} value={filter}>
                      {filter}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="200px"
                minHeight="40px"
              >
                <Select
                  style={{
                    height: "36px",
                    width: "200px",
                    outline: "none",
                    fontSize: "12px",
                    letterSpacing: "0.05rem",
                  }}
                  variant="outlined"
                  className="primary"
                  value={utmFilter}
                  onChange={(e: any) => setUTMFilter(e.target.value)}
                  // IconComponent={KeyboardArrowDown}
                >
                  {utm.map((filter, i) => (
                    <MenuItem key={i} value={filter}>
                      {filter}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                style={{
                  outline: "none",
                }}
              >
                <Select
                  style={{
                    height: "36px",
                    width: "200px",
                    outline: "none",
                    fontSize: "12px",
                    letterSpacing: "0.05rem",
                  }}
                  variant="outlined"
                  className="primary"
                  value={purpose}
                  placeholder="All"
                  onChange={(e: any) => setPurpose(e.target.value)}
                  IconComponent={KeyboardArrowDown}
                >
                  {purposeFilters.map((filter, i) => (
                    <MenuItem key={i} value={filter}>
                      {filter}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="200px"
                minHeight="40px"
              >
                <TextField
                  placeholder="Filter with pincode"
                  variant="outlined"
                  value={pincode}
                  size="small"
                  style={{
                    color: "yellow",
                    minWidth: "160px",
                    fontSize: "9px",
                    marginRight: "2px",
                  }}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setPincode(e.target.value);
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input1,
                    },
                  }}
                ></TextField>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={!pincode}
                  style={{
                    height: "36px",
                    maxWidth: "36px",
                    minWidth: "36px",
                  }}
                  onClick={handleFunc}
                >
                  <LocationOnOutlinedIcon />
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="200px"
                minHeight="40px"
              >
                <TextField
                  placeholder="Search with city "
                  variant="outlined"
                  size="small"
                  value={city}
                  style={{
                    color: "yellow",
                    fontSize: "9px",
                    minWidth: "160px",
                    marginRight: "2px",
                  }}
                  onChange={(e: any) => {
                    e.preventDefault();
                    console.log(e.target.value, "city status");
                    return setCity(e.target.value);
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input1,
                    },
                  }}
                ></TextField>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={!city}
                  style={{
                    height: "36px",
                    maxWidth: "36px",
                    minWidth: "36px",
                  }}
                  onClick={handleFunc}
                >
                  <LocationOnOutlinedIcon />
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="200px"
                minHeight="40px"
              >
                <TextField
                  placeholder="Search with state "
                  variant="outlined"
                  size="small"
                  value={state}
                  style={{
                    color: "yellow",
                    minWidth: "160px",
                    fontSize: "9px",
                    marginRight: "2px",
                  }}
                  onChange={(e: any) => {
                    e.preventDefault();
                    console.log(e.target.value, "city status");
                    return setState(e.target.value);
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input1,
                    },
                  }}
                ></TextField>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={!state}
                  style={{
                    height: "36px",
                    maxWidth: "36px",
                    minWidth: "36px",
                  }}
                  onClick={handleFunc}
                >
                  <LocationOnOutlinedIcon />
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="200px"
                minHeight="40px"
              >
                <TextField
                  placeholder="phone / email / order id "
                  variant="outlined"
                  size="small"
                  value={search}
                  style={{
                    color: "yellow",
                    minWidth: "160px",
                    marginRight: "2px",
                    fontSize: "9px",
                  }}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input1,
                    },
                  }}
                ></TextField>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={!search}
                  style={{
                    height: "36px",
                    maxWidth: "36px",
                    minWidth: "36px",
                  }}
                  onClick={onSearch}
                >
                  <SearchIcon />
                </Button>
              </Box>

              <Box display="flex">
                <DatePicker range={range} setRange={setRange} />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!range[0]}
                  style={{
                    height: "36px",
                    maxWidth: "36px",
                    minWidth: "36px",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    setDateAfter(
                      `${moment(range[0]).format("YYYY-MM-DD")}T00:00:00.000Z`
                    );
                    setDateBefore(
                      `${moment(range[1]).format("YYYY-MM-DD")}
									T23:59:59.999Z`
                    );
                    setOrder("createdAt_ASC");
                  }}
                >
                  <DateRangeOutlinedIcon />
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="200px"
                minHeight="40px"
              >
                <Select
                  style={{
                    height: "36px",
                    width: "200px",
                    outline: "none",
                    fontSize: "12px",
                    letterSpacing: "0.05rem",
                  }}
                  variant="outlined"
                  className="primary"
                  value={order}
                  placeholder="All"
                  onChange={(e: any) => {
                    setOrder(e.target.value);
                  }}
                  // IconComponent={KeyboardArrowDown}
                >
                  {orderOptions.map((filter, i) => (
                    <MenuItem key={i} value={filter.value}>
                      {filter.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Dialog>
          <Box>
            <SwipeableDrawer
              anchor={"right"}
              open={isOpen}
              onOpen={() => {}}
              onClose={() => {
                setIsOpen(false);
              }}
            >
              {context.user.email === "marketing@revos.in" ||
              context.user.email === "taijas@bolt.earth" ||
              context.user.userId === "618bd59eeb097a000885a169" ? (
                <Map rows={rows} setInfoDrawer={setInfoDrawer} />
              ) : null}
            </SwipeableDrawer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsOpen(true);
              }}
              style={{
                marginLeft: "8px",
                marginBottom: "8px",
                marginTop: "8px",
                boxShadow: "none",
              }}
            >
              {" "}
              Maps
            </Button>
          </Box>
          <Button
            variant="contained"
            disableElevation
            style={{
              background: "#24a0ed",
              color: "white",
              margin: "5px",
            }}
            onClick={async () => {
              await handleFunc();
            }}
          >
            Refresh
          </Button>
        </Paper>
        <Box width="100%" height="91%" display="flex" pt={2}>
          <Paper
            elevation={3}
            style={{
              padding: 12,
              margin: 0,
              border: "0.2px solid #44305660",
              height: "100%",
              width: "100%",
              // context.user.email === "marketing@revos.in" ||
              // context.user.userId ===
              // 	"618bd59eeb097a000885a169"
              // 	? "60%"
              // 	: "100%"
            }}
          >
            {/* 2) Table + Map */}
            <Box height="100%" className={classes.root}>
              <DataGrid
                rows={rows}
                columns={[
                  {
                    field: "userName",
                    headerName: "Name",
                    headerClassName: "column-header",
                    flex: 1.15,
                    renderCell: (params) => (
                      <Box
                        style={{
                          marginLeft: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {params.row.user.firstName}
                        &nbsp;
                        {params.row.user.lastName}
                      </Box>
                    ),
                    sortable: false,
                  },

                  {
                    field: "kycStatus",
                    sortable: false,
                    headerName: "Status",
                    headerClassName: "column-header",
                    flex: 1.15,
                    renderCell: (params) => (
                      <>
                        <Box
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {params.row.user.trackStatus}
                        </Box>
                      </>
                    ),
                  },

                  {
                    field: "createdAt",
                    headerName: "Created On",
                    headerClassName: "column-header",
                    flex: 1,
                    renderCell: (params) => (
                      <>
                        <Box
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {moment(params.row.user.createdAt).format(
                            "ddd, MMM DD, YYYY"
                          )}
                        </Box>
                      </>
                    ),
                  },

                  {
                    field: "updatedAt",
                    headerName: "Last Updated On",
                    headerClassName: "column-header",
                    flex: 1,
                    renderCell: (params) => (
                      <>
                        <Box
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {moment(params.row.user.updatedAt).format(
                            "ddd, MMM DD, YYYY"
                          )}
                        </Box>
                      </>
                    ),
                  },

                  {
                    field: "pincode",
                    sortable: false,
                    headerName: "Pincode",
                    headerClassName: "column-header",
                    flex: 0.9,
                    renderCell: (params) => (
                      <>
                        <Box
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {params.row.user.postalCode}
                        </Box>
                      </>
                    ),
                  },
                  {
                    field: "kycReminders",
                    headerName: "Kyc Reminders",
                    headerClassName: "column-header",
                    flex: 0.9,
                    renderCell: (params) => (
                      <>
                        <Box
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {params.row.user.kycReminders}
                        </Box>
                      </>
                    ),
                  },
                  {
                    field: "actions",
                    sortable: false,
                    headerName: "Actions",
                    headerClassName: "column-header",
                    flex: 0.65,
                    renderCell: (params) => (
                      <>
                        <InfoIcon
                          onClick={() => {
                            setInfoDrawer({
                              open: true,
                              data: params.row,
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            color: "#857F8A",
                            marginLeft: "5px",
                          }}
                        />
                      </>
                    ),
                  },
                ]}
                rowHeight={48}
                pageSize={40}
                rowsPerPageOptions={[10, 25, 50, 100]}
                loading={loading}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(ids) => {
                  selectedIDs = new Set(ids);
                  const selectedRowData = rows.filter((row) =>
                    selectedIDs.has(row.id.toString())
                  );
                  const EmailData = selectedRowData.map((row) => {
                    return row.user.email;
                  });
                  const PhoneData = selectedRowData.map((row) => {
                    return row.user.phone;
                  });
                  setEmailList(EmailData);
                  setPhoneList(PhoneData);
                  setSelectionModel(selectedRowData);
                }}
                selectionModel={selectedIDs}
                components={{
                  Footer: () => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      style={{
                        borderTop: "1px solid #00000025",
                      }}
                    >
                      <Box display="flex">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={!selectionModel.length}
                          style={{
                            marginLeft: "8px",
                            marginBottom: "8px",
                            marginTop: "8px",
                            boxShadow: "none",
                          }}
                          onClick={() => {
                            setNotifyDialog({
                              open: true,
                              data: {
                                emailList: emailList,
                                phoneList: phoneList,
                              },
                            });
                          }}
                        >
                          Notify User
                          {selectionModel.length > 1 ? "s" : ""}
                        </Button>
                        {selectionModel.length ? (
                          <Typography
                            style={{
                              marginLeft: "30px",
                              fontSize: "14px",
                              marginTop: "15px",
                            }}
                          >
                            {selectionModel.length} User
                            {selectionModel.length > 1 ? "s" : ""} Selected
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box
                        style={{
                          marginRight: "30px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            marginRight: "25px",
                          }}
                        >
                          {onPage === 1 ? 1 : (onPage - 1) * 4}
                          {onPage === 1 ? "" : 1}-
                          {filterCount < onPage * 40
                            ? filterCount
                            : onPage * 40}{" "}
                          of {filterCount}
                        </Typography>
                        <Button
                          disabled={onPage === 1}
                          onClick={() => {
                            setSkip(skip - 40);
                            setOnPage(onPage - 1);
                          }}
                        >
                          <Backward
                            style={{
                              width: "17px",
                            }}
                          />
                        </Button>
                        <Button
                          disabled={filterCount < onPage * 40 + 1}
                          onClick={() => {
                            setSkip(skip + 40);
                            setOnPage(onPage + 1);
                          }}
                        >
                          <Forward
                            style={{
                              width: "17px",
                            }}
                          />
                        </Button>
                      </Box>
                    </Box>
                  ),
                }}
              />
            </Box>
          </Paper>
          {/*  */}
        </Box>
      </Box>
      <InfoDrawer
        open={infoDrawer.open}
        data={infoDrawer.data}
        // setInfoDrawer={setInfoDrawer}

        handleOpen={() => {
          setInfoDrawer({ ...infoDrawer, open: true });
        }}
        handleClose={() => {
          setInfoDrawer({ ...infoDrawer, open: false });
        }}
        handleFunc={handleFunc}
      />
      <NotifyUser
        open={notifyDialog.open}
        handleClose={() => {
          setNotifyDialog({ ...notifyDialog, open: false });
        }}
        data={notifyDialog.data}
      />
      {/* {context.user.email === "marketing@revos.in" ||
      context.user.userId === "618bd59eeb097a000885a169" ? (
        <TrackKYC
          open={trackDialog.open}
          data={trackDialog.data}
          handleClose={() => setTrackDialog({ ...trackDialog, open: false })}
        />
      ) : (
        ""
      )} */}
    </Box>
  );
};

export default Devices;
