import { Box, Typography, Avatar, Grid, Paper } from "@mui/material";
import {
  AttachMoneyRounded,
  EventAvailableOutlined,
  PeopleOutlined,
  Bolt,
  EvStation,
  ListAlt,
} from "@mui/icons-material";
// import SocialDistanceOutlinedIcon from "@mui/icons-material/SocialDistanceOutlined";
interface Props {
  value: string;
  name: string;
}
interface MainProps {
  bookingData: Array<any>;
  totalUsers: Array<any>;
  earningData: Array<any>;
  totalAssets: Array<any>;
}
const BoxComponent: React.FC<Props> = (props: any) => {
  return (
    <Grid
      item
      xs={6}
      sx={{
        display: "flex",
        height: "55%",
        textAlign: "left",
        whiteSpace: "nowrap",
      }}
    >
      <Box
        component="span"
        sx={{
          p: 2,
          border: "1px solid #00000030",
          width: "80%",
          minWidth: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: "10px",
        }}
      >
        <Avatar
          variant="rounded"
          className="icon"
          sx={{
            borderRadius: "50%",
            backgroundColor: "rgba(60, 185, 158, 0.14)",
            color: "#3CB99E",
          }}
        >
          {props.children}
        </Avatar>
        <div>
          <Typography variant="h6">{props.value}</Typography>
          <Typography variant="inherit">{props.name}</Typography>
        </div>
      </Box>
    </Grid>
  );
};

const Total: React.FC<MainProps> = ({
  bookingData,
  totalAssets,
  earningData,
  totalUsers,
}) => {
  const calcTotalBookings = () => {
    console.log(bookingData, 'booked data')
    //  bookingdata.length === 0 ? '' :
     return `${bookingData && bookingData.length && bookingData[bookingData.length - 1].count || 0}`
  };
  const calcTotalAssets = () => {
    // console.log(totalAssets, "Assets data")
    return `${totalAssets && totalAssets.length && totalAssets[totalAssets.length - 1].count || 0}`
  };

  const calcTotalUsers = () => {
    //  totalUsers.length === 0 ? '' :
     return totalUsers && totalUsers.length && totalUsers[totalUsers.length - 1].count
  };

  const calcTotalEarning = () => {
    // earningData.length === 0 ? '' :
    return `${earningData && earningData.length && parseFloat(earningData[earningData.length - 1].earnings).toFixed(2) || 0}`
  };

  return (
    <Paper sx={{ width: "120%", height: "340px", boxShadow: "none" }}>
      <Box sx={{ paddingLeft: "2%" }}>
        <Grid
          container
          spacing={3}
          sx={{
            height: "80%",
            padding: "15px",
            margin: "10px",
            maxWidth: "35vw",
            marginLeft: "15px",
          }}
        >
          <BoxComponent value ={"" + calcTotalBookings()}  name="Bookings">
            <EventAvailableOutlined />
          </BoxComponent>
          {/* <BoxComponent value='1268' name='New Users'><PeopleOutlined /></BoxComponent> */}
          <BoxComponent value={calcTotalUsers()} name="Total Users">
            <PeopleOutlined />
          </BoxComponent>
          {/* <BoxComponent value='3456 Kwh' name='Energy'><EvStation/></BoxComponent> */}
          {/* <BoxComponent value='15,890 Kms' name='Distance'><SocialDistanceOutlinedIcon /></BoxComponent> */}
          <BoxComponent value={"₹ " + calcTotalEarning()} name="Earnings">
            <AttachMoneyRounded />
          </BoxComponent>
          <BoxComponent value={calcTotalAssets()} name="Total Assets">
            <ListAlt />
          </BoxComponent>
        </Grid>
      </Box>
    </Paper>
  );
};

export default Total;
