import React, { FC, useState, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { GridOverlay, DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import SearchBar from "../../../global/SearchBar/index";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .column-header": {
      fontSize: "13px",
    },
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Users = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [editDialog, setEditDialog] = useState({ open: false, data: {} });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    data: {},
  });
  const FEED_QUERY = gql`
    {
      userQ {
        getAllUsers {
          email
          firstName
          lastName
          status
          id
          role
        }
      }
    }
  `;

  const { data, refetch } = useQuery(FEED_QUERY);

  useEffect(() => {
    if (data) {
      setRows(data.userQ.getAllUsers);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    let chargeSearch = rows;
    if (search) {
      chargeSearch = chargeSearch.filter(
        ({ firstName, lastName, email, status }) =>
          [firstName, lastName, email, status].some(
            (el) =>
              el && String(el).toLowerCase().includes(search.toLowerCase())
          )
      );
      setRows(chargeSearch);
    }
  }, [search]);

  console.log(rows);
  const classes = useStyles();

  const handleClose = () => {
    setEditDialog({ ...editDialog, open: false });
  };

  return (
    <Paper
      elevation={3}
      style={{ padding: 12, margin: 0, border: "0.2px solid #44305660" }}
    >
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" style={{ marginLeft: "5px" }}>
          Users
        </Typography>
        <Box display="flex" alignItems="center">
          <SearchBar
            handleSearch={setSearch}
            persist
            enableClear={Boolean(search)}
            disableFlex
            placeholder="Search..."
            id="1"
          />
        </Box>
        {/* <Button
          variant="contained"
          disableElevation
          style={{ marginRight: '5px' }}
          startIcon={<AddIcon />}
        >
          New
        </Button> */}
      </Box>
      <Box height={"80vh"} className={classes.root}>
        <DataGrid
          rows={rows || []}
          columns={[
            {
              field: "first name",
              headerName: "First Name",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <Box style={{ marginLeft: "5px" }}>{params.row.firstName}</Box>
              ),
            },
            {
              field: "last name",
              headerName: "Last Name",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <Box style={{ marginLeft: "5px" }}>{params.row.lastName}</Box>
              ),
            },
            {
              field: "role",
              headerName: "Role",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <Box style={{ marginLeft: "5px" }}>{params.row.role}</Box>
              ),
            },
            // {
            //   field: "email",
            //   headerName: "Email",
            //   headerClassName: "column-header",
            //   flex: 1,
            //   renderCell: (params) => (
            //     <Box style={{ marginLeft: "5px" }}>{params.row.email}</Box>
            //   ),
            // },

            {
              field: "status",
              headerName: "Status",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <Box style={{ marginLeft: "5px" }}>{params.row.status}</Box>
              ),
            },
            {
              field: "actions",
              headerName: "Actions",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setEditDialog({ open: true, data: params.row });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton
                    onClick={() =>
                      setDeleteDialog({ open: true, data: params.row })
                    }
                  >
                    <DeleteIcon />
                  </IconButton> */}
                </>
              ),
            },
          ]}
          rowHeight={48}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          loading={loading}
        />
        <EditDialog
          open={editDialog.open}
          data={editDialog.data}
          handleClose={handleClose}
          refetch={refetch}
          loading={loading}
          setLoading={setLoading}
          FEED_QUERY={FEED_QUERY}
        />
      </Box>
    </Paper>
  );
};

interface EditDialogProps {
  open: boolean;
  data: any;
  FEED_QUERY: any;
  handleClose: () => void;
  refetch: () => void;
  loading: boolean;
  setLoading: (active: boolean) => void;
}

const EditDialog: React.FC<EditDialogProps> = ({
  open,
  data,
  handleClose,
  refetch,
  loading,
  setLoading,
  FEED_QUERY,
}) => {
  const [editData, setEditData] = useState({
    status: "",
    role: "",
  });

  useEffect(() => {
    if (open) {
      setEditData({ status: data.status, role: data.role });
    } else {
      setEditData({ status: "", role: "" });
    }
  }, [open]);

  function handleChange(key: any, value: any) {
    setEditData((prevInput) => ({ ...prevInput, [key]: value }));
  }

  const statusList = ["ACTIVE", "INACTIVE"];

  const roleList = [
    "ADMIN",
    "INVENTORY_MANAGER",
    "ASSEMBLER",
    "DISTRIBUTOR",
    "DEALER",
    "SALES",
    "OPERATIONS_RS_DEPLOY",
    "RIDER",
    "USER",
    "UNAUTHORISED",
    "DEV",
    "RENTAL_RIDER",
  ];

  const UPDATE_STATUS = gql`
    mutation UpdateUser(
      $updateUserWhere: UserWhereInput
      $updateUserData: UserInput
    ) {
      userM {
        updateUser(where: $updateUserWhere, data: $updateUserData) {
          status
          role
        }
      }
    }
  `;

  console.log(refetch);

  const [updateStatus] = useMutation(UPDATE_STATUS, {
    refetchQueries: [{ query: FEED_QUERY }],
  });

  const saveData = (e: any) => {
    e.preventDefault();
    updateStatus({
      variables: {
        updateUserWhere: { id: data.id },
        updateUserData: editData,
      },
    });

    handleClose();
  };

  const classes = useStyles();
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">Edit User Status</Typography>
        <Typography style={{ fontSize: "0.8rem" }} variant="h6">
          {data.firstName}&nbsp;{data.lastName}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          margin="0 auto"
          marginBottom="30px"
          style={{ marginTop: "30px" }}
          width={450}
          display="grid"
          gridGap={16}
          gridTemplateColumns="3fr 6fr"
          alignItems="end"
        >
          <Typography style={{ marginBottom: "20px" }} gutterBottom>
            Status
          </Typography>
          <FormControl
            variant="outlined"
            required
            className={classes.formControl}
            style={{ outline: "none", marginTop: "30px" }}
          >
            <Select
              native
              onChange={(e) => {
                handleChange("status", e.target.value);
              }}
              name="status"
              defaultValue={data.status}
            >
              <option value={data.status}>{data.status}</option>
              {statusList.map((item, index) => {
                if (item === data.status) {
                  return "";
                } else {
                  return <option value={item}>{item}</option>;
                }
              })}
            </Select>
          </FormControl>
          <Typography style={{ marginBottom: "20px" }} gutterBottom>
            Role
          </Typography>
          <FormControl
            variant="outlined"
            required
            className={classes.formControl}
            style={{ outline: "none", marginTop: "30px" }}
          >
            <Select
              native
              onChange={(e) => {
                handleChange("role", e.target.value);
              }}
              name="role"
              defaultValue={data.role}
            >
              <option value={data.role}>{data.role}</option>
              {roleList.map((item, index) => {
                if (item === data.role) {
                  return "";
                } else {
                  return <option value={item}>{item}</option>;
                }
              })}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={saveData}
          style={{
            width: "150px",
            margin: "20px",
            height: "40px",
            padding: "25px",
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Users;
