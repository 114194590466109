import { FC, useContext } from "react";
import { AuthContext } from "../../../../context/auth";
import { downloadInvoice } from "../utils/downloadInvoice";
import { Address } from "./Address";
import { ShippingForm } from "./ShippingForm";

export const DetailedView: FC<{ row: any; setRows: any }> = ({
  row,
  setRows,
}) => {
  const context: any = useContext(AuthContext);

  const shippingAddress = row.shippingAddress;

  const billingAddress = row.billingAddress;

  const refund = row.refund;

  const status = row.status;

  const quantity = row.quantity;

  const product = row.product;

  const activeTariff = product.tariffs.find(
    (tariff: any) => tariff.status === "ACTIVE"
  );

  const priceComponent = activeTariff.priceComponents.find(
    (component: any) => component.type === "PRODUCT"
  );

  const gst = priceComponent.price * priceComponent.gst * 0.01;

  const unitPrice = priceComponent.price - gst;

  const shipping = row.shippings.find(
    (shipping: any) => shipping.status === "ACTIVE"
  );

  const shippingCost = shipping.cost;

  const orderTotal = row.amount;

  const payment = row.invoice.payment;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // alignContent: "space-around",
        gap: "1rem",
        padding: "20px",
        height: "100%",
        flexWrap: "wrap",
      }}
    >
      <p
        style={{
          fontSize: "14px",
          fontWeight: "normal",
          margin: "0",
        }}
      >
        Order Id: {row.id}
      </p>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "normal",
          margin: "0",
        }}
      >
        HSN/SAC: {product.HSN_SAC}
      </p>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "normal",
          margin: "0",
        }}
      >
        Quantity: {quantity}
      </p>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "normal",
          margin: "0",
        }}
      >
        Unit Price: {unitPrice}
      </p>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "normal",
          margin: "0",
        }}
      >
        GST: {gst}
      </p>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "normal",
          margin: "0",
        }}
      >
        Order Total: {orderTotal}
      </p>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "normal",
          margin: "0",
        }}
      >
        Shipping Cost: {shippingCost}
      </p>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "normal",
          margin: "0",
        }}
      >
        Grand Total: {orderTotal + shippingCost}
      </p>
      <div>
        {shippingAddress && (
          <Address address={shippingAddress} title={"Shipping Address"} />
        )}
        {billingAddress && (
          <Address address={billingAddress} title={"Billing Address"} />
        )}
      </div>
      {status === "CANCELLED" && payment && (
        <div>
          <h1
            style={{
              fontSize: "14px",
              fontWeight: "normal",
            }}
          >
            Payment:
          </h1>
          <ul
            style={{
              listStyle: "none",
              paddingLeft: "0px",
            }}
          >
            <li>Payment Status: {payment.status}</li>
            <li>Payment Id: {payment.paymentId}</li>
          </ul>
        </div>
      )}
      {status === "CANCELLED" && refund && (
        <div>
          <h1
            style={{
              fontSize: "14px",
              fontWeight: "normal",
            }}
          >
            Refund:
          </h1>
          <ul
            style={{
              listStyle: "none",
              paddingLeft: "10px",
            }}
          >
            <li>Refund Status: {refund.status}</li>
            <li>Refund Id: {refund.refundId}</li>
          </ul>
        </div>
      )}
      {(context.user.email === "marketing@revos.in" ||
        context.user.email === "taijas@bolt.earth" ||
        context.user.userId === "618bd59eeb097a000885a169" ||
        context.user.email === "courier@revos.in" ||
        context.user.userId === "61a34c81f42ea00007fbc17d") &&
        ["CONFIRMED"].includes(status) && (
          <ShippingForm row={row} setRows={setRows} />
        )}
      {["DISPATCHED", "DELIVERED"].includes(status) && (
        <div>
          <h1
            style={{
              fontSize: "14px",
              fontWeight: "normal",
            }}
          >
            Shipping Details:
          </h1>
          <ul
            style={{
              listStyle: "none",
              paddingLeft: "0px",
            }}
          >
            {shipping.provider && <li>Provider: {shipping.provider}</li>}
            <li>Shipment Id: {shipping.shipmentId}</li>
            <li>BOLT UID: {shipping.itemId}</li>
            <li>
              Dispatch Date:{" "}
              {new Date(shipping.dispatchDate).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              })}
            </li>
          </ul>
        </div>
      )}
      {["DISPATCHED", "DELIVERED"].includes(status) && (
        <button
          className="btn btn-primary"
          onClick={async () => {
            // pdfMake.createPdf(generateInvoice(row)).open();
            await downloadInvoice(row.invoice._id);
          }}
        >
          Download Tax Invoice
        </button>
      )}
    </div>
  );
};
