import React from "react";
import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#443056",
      light: "#ffffff",
    },
    secondary: {
      main: "#3C3E50",
    },
  },
});

export default theme;
