import React from 'react'
import Request from './Requests'
import ApiTable from './ApiTable'
import UsersTable from './UsersTable'
import PackagesTable from './PackagesTable'
import { Box, Grid, Typography, Paper } from '@material-ui/core'

const GridService: React.FC = () => {
    return (
        /*@ts-ignore*/
        <Paper sx={{ boxShadow: "none" }}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}><Request /></Grid>
                    <Grid item xs={6}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant='h6'>Top 5 API Hits</Typography>
                            <br/>
                            <ApiTable />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {/*@ts-ignore*/}
                        <Paper sx={{
                            boxShadow: "none",
                            border: "1px solid #00000020",
                            borderRadius: "10px",
                        }}>
                            <div
                                style={{
                                    marginLeft: '10px'
                                }}
                            >
                                <Typography variant='h6'>Top App Users</Typography>
                            </div>
                            <br/>
                            {/*@ts-ignore*/}
                            <Grid item xs={11} sx={{ marginTop: "10px" }}>
                                <UsersTable />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                    {/*@ts-ignore*/}
                    <Paper sx={{
                        boxShadow: "none",
                        border: "1px solid #00000020",
                        borderRadius: "10px",
                    }}>
                        <div
                            style={{
                                marginLeft: '10px'
                            }}
                        >
                            <Typography variant='h6'>Top Companies</Typography>
                        </div>
                        {/*@ts-ignore*/}
                        <Grid item xs={11} sx={{ marginTop: "10px" }}>
                            <PackagesTable />
                        </Grid>
                    </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Paper >
    )
}

export default GridService
