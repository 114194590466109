import Users from "../../pages/dashboard/sections/Users";
import Company from "../../pages/dashboard/sections/Company";
import Chargers from "../../pages/dashboard/sections/Chargers";
import Devices from "../../pages/dashboard/sections/Devices";
import Vehicles from "../../pages/dashboard/sections/Vehicles";
import Overview from "../../pages/dashboard/overview/index";
import Service from "../../pages/dashboard/overview/service"

import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import DriveEtaRoundedIcon from "@material-ui/icons/DriveEtaRounded";
import SettingsCellRoundedIcon from "@material-ui/icons/SettingsCellRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import EvStationRoundedIcon from "@material-ui/icons/EvStationRounded";

export const adminRouter = [
  {
    icon: HomeRoundedIcon,
    label: "Application",
    path: "/overview",
    template: "admin",
    component: Overview,
  },
  {
    icon: PersonRoundedIcon,
    label: "Service",
    path: "/service",
    template: "admin",
    component: Service,
  }
]

export const router = [
  {
    icon: HomeRoundedIcon,
    label: "Company",
    path: "/company",
    template: "deployment",
    component: Company,
  },
  {
    icon: DriveEtaRoundedIcon,
    label: "Vehicles",
    path: "/vehicles",
    template: "deployment",
    component: Vehicles,
  },
  {
    icon: SettingsCellRoundedIcon,
    label: "Devices",
    path: "/devices",
    template: "deployment",
    component: Devices,
  },
  {
    icon: PersonRoundedIcon,
    label: "Users",
    path: "/users",
    template: "deployment",
    component: Users,
  },
  {
    icon: EvStationRoundedIcon,
    label: "Chargers",
    path: "/chargers",
    template: "deployment",
    component: Chargers,
  },
];
