export const dispatchOrder = async ({
	orderId,
	provider,
	shipmentId,
	itemId,
	dispatchDate
}: {
	orderId: string;
	provider: string;
	shipmentId: string;
	itemId: string;
	dispatchDate: Date;
}) => {
	const response = await fetch(
		`${process.env.REACT_APP_MARKET_SERVICE_URL}/orders/dispatch`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				orderId,
				provider,
				shipmentId,
				itemId,
				dispatchDate
			})
		}
	);
	const data = await response.json();
	console.log(data);
	if (data.status === 200) {
		alert("Order dispatched successfully");
	} else {
		alert(data.message);
	}
};
