import React from "react";
import "./mapStyles/Marker.css";
import { Paper, Box, Button } from "@material-ui/core";

interface Props {
  setInfoDrawer: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      data: {};
    }>
  >;
  lat: any;
  lng: any;
  name: any;
  color: any;
  obj: any;
}

const Marker: React.FC<Props> = ({ color, name, setInfoDrawer, obj }) => {
  return (
    <div
      className="marker"
      style={{ backgroundColor: color, cursor: "pointer" }}
      title={name}
      onClick={() => {
        setInfoDrawer({ open: true, data: obj });
      }}
    />
  );
};

export default Marker;
