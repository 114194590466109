export const getDistanceData = (isMaster, start_date, end_date, id) => {
    let url = `https://datafeed.revos.in/housekeeping/lease/distance?token=1234&id=${id}&startTime=${start_date}&endTime=${end_date}`;
    if (isMaster) url = url + "&master=true";
    return fetch(url, {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return data.data;
      });
  };