export const getNewUserData = async (
  type,
  range
) => {
  try {
    let url = `https://datafeed.revos.in/housekeeping/${type}/totalUsers?token=1234&timeframe=${range}`;
    // if (isMaster) url = url + "&master=true";
    let res = await fetch(url, {
      method: "GET",
    });
    res = await res.json();
    return res;
  } catch (e) {
    console.log(e);
    return { data: [] };
  }
};
