import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

// TODO: enable/disable case sensitive search

const SearchBar = ({
  handleSearch,
  id,
  placeholder,
  persist,
  enableClear,
  disableFlex,
}) => {
  const [focused, setFocus] = useState(false);
  const [input, setInput] = useState("");
  function handleKeyPress(e) {
    if (e.key === "Enter" && e.target.value !== "") {
      handleSearch(e.target.value);
      if (!persist) setInput("");
    }
  }
  return (
    <Box display={"flex"}>
      <label
        htmlFor={id ? id : "searchbar"}
        style={{
          color: "#757575",
          cursor: "text",
          paddingRight: 5,
        }}
      >
        <SearchIcon fontSize="small" style={{}} />
      </label>

      <input
        id={id ? id : "searchbar"}
        type="text"
        placeholder={placeholder || "Search..."}
        value={input}
        style={{
          fontSize: 14,
          outline: "none",
          transition: "all 150ms",
          width: focused ? 150 : 100,
          border: "none",
          height: 20,
        }}
        autoComplete="off"
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onKeyPress={handleKeyPress}
      />
      {enableClear && (
        <IconButton
          onClick={() => {
            handleSearch("");
            setInput("");
          }}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default SearchBar;
