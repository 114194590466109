import React from "react";
import { Paper } from "@mui/material";
import GridService from "./ServiceStatsComponents/GridService";

const Service: React.FC = () => {
    return (
        <Paper
            elevation={3}
            style={{
                padding: 12,
                margin: 0,
                border: "0.2px solid #44305660",
            }}
        >
            <GridService/>
         </Paper>
    );
};

export default Service;