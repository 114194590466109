import React, { useContext, FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AuthContext } from "../../context/auth";

interface AppRouteProps extends RouteProps {}

export const AppRoute: FC<AppRouteProps> = ({ ...rest }) => {
  const context: any = useContext(AuthContext);
  if (
    context.user &&
    (context.user.email === "tester@revos.in" ||
      context.user.email === "taijas@bolt.earth")
  ) {
    return <Redirect to="/app/deployment/company" />;
  }
  if (
    context.user &&
    (context.user.email === "marketing@revos.in" ||
      context.user.email === "taijas@bolt.earth")
  ) {
    return <Redirect to="/app/orders" />;
  }
  if (
    context.user &&
    (context.user.email === "operations@revos.in" ||
      context.user.email === "taijas@bolt.earth")
  ) {
    return <Redirect to="/app/orders" />;
  }
  if (
    context.user &&
    (context.user.email === "courier@revos.in" ||
      context.user.email === "taijas@bolt.earth")
  ) {
    return <Redirect to="/app/orders" />;
  }
  if (
    context.user &&
    (context.user.email === "admin@revos.in" ||
      context.user.email === "taijas@bolt.earth")
  ) {
    return <Redirect to="/app/overview" />;
  }

  return <Route {...rest} />;
};

interface PrivateRouteProps extends RouteProps {}

export const PrivateRoute: FC<PrivateRouteProps> = ({ ...rest }) => {
  const context = useContext(AuthContext);
  if (!context.user) return <Redirect to="/onboard" />;
  return <Route {...rest} />;
};
