import React, { FC, useState, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchBar from "../../../global/SearchBar/index";
import { ExposurePlus1 } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .column-header": {
			fontSize: "13px"
		}
	},
	dialogTitle: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	dialogContent: {
		padding: theme.spacing(4)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	margin: {
		margin: theme.spacing(1)
	},
	table: {
		width: "100%",
		marginBottom: 20
	}
}));

const Chargers = () => {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [editDialog, setEditDialog] = useState({ open: false, data: {} });
	const [otaDialog, setOTADialog] = useState({ open: false, data: {} });
	const [deleteDialog, setDeleteDialog] = useState({
		open: false,
		data: {}
	});

	const [data, setData] = useState<any>();
	// const FEED_QUERY = gql`
	// 	{
	// 		chargersQ {
	// 			getAllChargers {
	// 				id
	// 				status
	// 				UID
	// 				specs {
	// 					name
	// 					key
	// 					value
	// 					id
	// 				}
	// 			}
	// 		}
	// 	}
	// `;

	// const { data, refetch } = useQuery(FEED_QUERY);

	const refetch = () => {
		setLoading(true);
		fetch(`https://bolt.revos.in/misc/chargers`, {
			headers: {

				"Content-Type": "application/json",
				token: "1234",
				Authorization:
					"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1Y2ZhNDNjNmE3YjExYjAwMDczZjk2NTYiLCJpYXQiOjE2NDIwNTQ0NDl9.qhibQNzbTZ5bA9oP4EwCcLRn9EnQosBmofMvOHx_bd8"
			}
		})
			.then((response) => response.json())
			.then((data) => {
				setData(data);
				const rows = (data && data.data && data.data.constructor === Array ? data.data : []).map((item: any) => {
					const chargerId = item.chargerId;
					return {
						id: chargerId,
						UID: chargerId,
						firmware: item.firmware,
						expectedFirmware: item.expectedFirmware,
						otaStatus: item.otaStatus
					};
				});
				setRows(rows);
				setLoading(false);

				console.log(data);
			});

		// const rows = (data && data.data && data.data.constructor === Array ? data.data : []).map((item: any) => {
		// 	const chargerId = item.chargerId;
		// 	return {
		// 		id: chargerId,
		// 		UID: chargerId,
		// 		firmware: item.firmware,
		// 		expectedFirmware: item.expectedFirmware,
		// 		otaStatus: item.otaStatus
		// 	};
		// });
		// setRows(rows);

		// setLoading(false);
	};

	useEffect(() => {
		if (!data) {
			fetch(`https://bolt.revos.in/misc/chargers`, {
				headers: {
					"Content-Type": "application/json",
					token: "1234",
					Authorization:
						"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1Y2ZhNDNjNmE3YjExYjAwMDczZjk2NTYiLCJpYXQiOjE2NDIwNTQ0NDl9.qhibQNzbTZ5bA9oP4EwCcLRn9EnQosBmofMvOHx_bd8"
				}
			})
				.then((response) => response.json())
				.then((data) => {
					setData(data);
					console.log("data", (data && data.data && data.data.constructor === Array ? data.data : []).length);
				});
		}

		if (data) {
			console.log(data);

			const rows = (data && data.data && data.data.constructor === Array ? data.data : []).map((item: any) => {
				const chargerId = item.chargerId;
				return {
					id: chargerId,
					UID: chargerId,
					firmware: item.firmware,
					expectedFirmware: item.expectedFirmware,
					otaStatus: item.otaStatus
				};
			});
			setRows(rows);
			setSearch(search);
			setLoading(false);
			// data.chargersQ.getAllChargers.map((el: any) => {
			// 	if (el.name === "firmware") {
			// 		console.log(el);
			// 	} else {
			// 		console.log("NotFound");
			// 	}
			// });
		}

		setSearch("");
	}, [data]);

	useEffect(() => {
		let chargeSearch = rows;
		if (search) {
			chargeSearch = chargeSearch.filter(({ UID }) =>
				[UID].some(
					(el) =>
						el &&
						String(el).toLowerCase().includes(search.toLowerCase())
				)
			);
			setRows(chargeSearch);
		}
	}, [search]);

	console.log(rows);

	const handleCloseOTA = () => {
		setOTADialog({ ...otaDialog, open: false });
	};
	const handleCloseDEL = () => {
		setDeleteDialog({ ...deleteDialog, open: false });
	};
	const classes = useStyles();

	return (
		<Paper
			elevation={3}
			style={{
				padding: 12,
				margin: 0,
				border: "0.2px solid #44305660"
			}}
		>
			<Box
				mb={1}
				display='flex'
				justifyContent='space-between'
				alignItems='center'
			>
				<Typography variant='h6' style={{ marginLeft: "5px" }}>
					Chargers
				</Typography>
				<Box display='flex' alignItems='center'>
					<SearchBar
						handleSearch={setSearch}
						persist
						enableClear={Boolean(search)}
						disableFlex
						placeholder='Search...'
						id='1'
					/>
				</Box>
				{/* <Button
          variant="contained"
          disableElevation
          style={{ marginRight: '5px' }}
          startIcon={<AddIcon />}
        >
          New
        </Button> */}
			</Box>
			<Box height={"80vh"} className={classes.root}>
				<DataGrid
					rows={rows || []}
					columns={[
						{
							field: "uid",
							headerName: "UID",
							headerClassName: "column-header",
							flex: 1,
							renderCell: (params) => (
								<Box style={{ marginLeft: "5px" }}>
									{params.row.UID}
								</Box>
							)
						},

						{
							field: "ota",
							headerName: "Update Firmware Version",
							headerClassName: "column-header",
							flex: 1,
							renderCell: (params) => (
								<>
									<Button
										onClick={(e) => {
											e.preventDefault();
											setOTADialog({
												open: true,
												data: params.row
											});
										}}
										variant='outlined'
										size='small'
										disableElevation
										style={{ marginRight: "10px" }}
									>
										Update
									</Button>
								</>
							)
						},

						{
							field: "actions",
							headerName: "Actions",
							headerClassName: "column-header",
							flex: 1,
							renderCell: (params) => (
								<>
									<IconButton
										onClick={() =>
											setDeleteDialog({
												open: true,
												data: params.row
											})
										}
									>
										<DeleteIcon />
									</IconButton>
								</>
							)
						}
					]}
					rowHeight={48}
					pageSize={10}
					rowsPerPageOptions={[10, 25, 50, 100]}
					loading={loading}
					disableSelectionOnClick
				/>
			</Box>
			<UpdateOTADialog
				open={otaDialog.open}
				handleClose={handleCloseOTA}
				data={otaDialog.data}
				refetch={refetch}
				setLoading={setLoading}
				setRows={setRows}
			/>
			<DeleteChargerDialog
				open={deleteDialog.open}
				handleClose={handleCloseDEL}
				data={deleteDialog.data}
				refetch={refetch}
				setRows={setRows}
			// FEED_QUERY={FEED_QUERY}
			/>
		</Paper>
	);
};

interface UpdateOTADialogProps {
	open: boolean;
	data: any;
	handleClose: () => void;
	refetch: () => void;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setRows: React.Dispatch<React.SetStateAction<any>>;
}

const UpdateOTADialog: React.FC<UpdateOTADialogProps> = ({
	open,
	data,
	handleClose,
	refetch,
	setLoading,
	setRows
}) => {
	const [currentVersion, setCurrentVersion] = useState({
		version: ""
	});
	const [status, setStatus] = useState({
		status: ""
	});
	const [expectedVersion, setExpectedVersion] = useState({
		expectedFirmware: ""
	});

	console.log(data);

	useEffect(() => {
		if (open) {
			// data.specs.filter((el: any) => {
			// 	switch (el.key) {
			// 		case "version":
			// 			setCurrentVersion({ version: el.value });
			// 			break;
			// 		case "otaStatus":
			// 			setStatus({ status: el.value });
			// 			break;
			// 		case "expectedFirmware":
			// 			setExpectedVersion({ expectedFirmware: el.value });
			// 			break;
			// 	}
			// });
			setCurrentVersion({ version: data.firmware });
			setStatus({ status: data.otaStatus });
			setExpectedVersion({ expectedFirmware: data.expectedFirmware });
		} else {
			setCurrentVersion({ version: "" });
			setStatus({ status: "" });
			setExpectedVersion({ expectedFirmware: "" });
		}
	}, [open, data]);

	function handleUpdate() {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization:
				"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1Y2ZhNDNjNmE3YjExYjAwMDczZjk2NTYiLCJpYXQiOjE2NDIwNTQ0NDl9.qhibQNzbTZ5bA9oP4EwCcLRn9EnQosBmofMvOHx_bd8",
			    token: "1234",
			},
			body: JSON.stringify({
				expectedFirmware: data?.expectedFirmware
			})
		};
		fetch(
			`https://bolt.revos.in/charger/${data.UID}/markOtaUpdate`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
			});
		setLoading(true);
		setRows([]);
		refetch();

		handleClose();
	}

	console.log(data.UID);

	const classes = useStyles();
	return (
		<Dialog open={open} maxWidth='sm' fullWidth>
			<DialogTitle disableTypography className={classes.dialogTitle}>
				<Typography variant='h6'>Edit OTA Status</Typography>
				<Typography style={{ fontSize: "0.8rem" }} variant='h6'>
					{data.UID}
				</Typography>
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={() => {
						handleClose();
						// setOTAValues({ version: "", status: "", expectedFirmware: "" });
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers style={{ padding: 0 }}>
				<Box maxHeight={250}>
					<Table size='small' className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell
									colSpan={100}
									style={{ paddingTop: 0, paddingBottom: 0 }}
								>
									<h2 style={{ fontSize: "13px" }}>
										Version Info:
									</h2>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow key='OTA Status'>
								<TableCell
									style={{
										paddingTop: "15px",
										paddingBottom: "15px"
									}}
								>
									OTA Status
								</TableCell>
								<TableCell colSpan={2} style={{ width: "55%" }}>
									{status.status === ""
										? "Not Available"
										: status.status}
								</TableCell>
							</TableRow>
							<TableRow key='Current Version'>
								<TableCell
									style={{
										paddingTop: "15px",
										paddingBottom: "15px"
									}}
								>
									Current Version
								</TableCell>
								<TableCell colSpan={2} style={{ width: "55%" }}>
									{!currentVersion.version
										? "Not Available"
										: currentVersion.version}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Table
						size='small'
						className={classes.table}
						style={{ marginTop: "-15px" }}
					>
						<TableHead>
							<TableRow>
								<TableCell
									colSpan={100}
									style={{ paddingTop: 0, paddingBottom: 0 }}
								>
									<h2 style={{ fontSize: "13px" }}>
										Update Version:
									</h2>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow key='Current Version'>
								<TableCell
									style={{
										paddingTop: "15px",
										paddingBottom: "15px"
									}}
								>
									Expected Version
								</TableCell>
								<TableCell
									colSpan={200}
									style={{ width: "55%" }}
								>
									<TextField
										style={{ fontSize: "10px" }}
										placeholder='Add a version...'
										value={expectedVersion.expectedFirmware}
										onChange={(e) => {
											setExpectedVersion({
												expectedFirmware: e.target.value
											});
										}}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Box>
				{/* <Box
          margin="0 auto"
          style={{ marginTop: "30px", marginBottom: "50px" }}
          width={450}
          display="grid"
          gridGap={16}
          gridTemplateColumns="6fr 4fr"
          alignItems="end"
        >
          <Typography gutterBottom>Expected Firmware Version</Typography>
          <TextField
            value={expectedVersion.expectedFirmware}
            onChange={(e) => {
              handleChange("expectedFirmware", e.target.value);
            }}
          />
        </Box> */}
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					style={{
						width: "150px",
						margin: "20px",
						height: "40px",
						padding: "25px"
					}}
					onClick={handleUpdate}
				>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
};

interface DeleteChargerDialogProps {
	open: boolean;
	data: any;
	handleClose: () => void;
	refetch: () => void;
	setRows: (rows: any) => void;
	// FEED_QUERY: any;
}

const DeleteChargerDialog: React.FC<DeleteChargerDialogProps> = ({
	open,
	data,
	handleClose,
	refetch,
	setRows
	// FEED_QUERY
}) => {
	// const DELETE_DEVICE = gql`
	// 	mutation deleteCharger($deleteChargerWhere: ID) {
	// 		chargersM {
	// 			deleteCharger(where: $deleteChargerWhere) {
	// 				UID
	// 			}
	// 		}
	// 	}
	// `;

	// const [deleteCharger] = useMutation(DELETE_DEVICE, {
	// 	refetchQueries: [{ query: FEED_QUERY }]
	// });

	function handleDelete() {
		// deleteCharger({
		// 	variables: {
		// 		deleteChargerWhere: data.id
		// 	}
		// });
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				token: "1234",
				Authorization:
					"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1Y2ZhNDNjNmE3YjExYjAwMDczZjk2NTYiLCJpYXQiOjE2NDIwNTQ0NDl9.qhibQNzbTZ5bA9oP4EwCcLRn9EnQosBmofMvOHx_bd8"
			},
			body: JSON.stringify({
				secretCode: "allezzwell"
			})
		};
		fetch(
			`https://bolt.revos.in/restricted/${data.UID}/permDelete`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
			});

		// const requestOptions2 = {
		// 	method: "GET",
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 		token: "1234",
		// 		Authorization:
		// 			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1Y2ZhNDNjNmE3YjExYjAwMDczZjk2NTYiLCJpYXQiOjE2NDIwNTQ0NDl9.qhibQNzbTZ5bA9oP4EwCcLRn9EnQosBmofMvOHx_bd8"
		// 	}
		// };
		// fetch(
		// 	`https://api.revos.in/v2/charger/${data.UID}/permdelete`,
		// 	requestOptions2
		// )
		// 	.then((response) => response.json())
		// 	.then((data) => {
		// 		console.log(data);
		// 	});
		setRows([]);
		refetch();
		handleClose();
	}
	console.log(data.id);
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Delete Charger</DialogTitle>
			<DialogContent dividers>
				<Typography gutterBottom style={{ minWidth: 250 }}>
					Are you sure you want to delete{" "}
					<strong>"{data.UID}"</strong> ?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button
					variant='contained'
					color='primary'
					disableElevation
					onClick={handleDelete}
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Chargers;
