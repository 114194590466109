import React from "react";
import { Paper, Box, Button } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
interface Props {
	setInfoDrawer: React.Dispatch<
		React.SetStateAction<{
			open: boolean;
			data: {};
		}>
	>;
	rows: any[];
}

const Map: React.FC<Props> = ({ rows, setInfoDrawer }) => {
	console.log(rows, "rows data");
	return (
		<div style={{ width: "50vw", height: '100vh' }}>
			<Paper
				style={{
					height: "100%",
					padding: 12,
					marginLeft: 15
				}}
			>
				<GoogleMapReact
					bootstrapURLKeys={{
						key: process.env.REACT_APP_GMAPS_API_KEY as string
					}}
					defaultZoom={5}
					defaultCenter={{ lat: 20.5937, lng: 78.9629 }}
				>
					{rows.map((obj) => (
						<Marker
							lat={isNaN(obj.user.latitude)? 0 : obj.user.latitude} 
							lng={isNaN(obj.user.longitude)? 0 : obj.user.longitude}
							name={`${obj.user.firstName}${" "}${
								obj.user.lastName
							}`}
							color='#68418a'
							setInfoDrawer={setInfoDrawer}
							obj={obj}
						></Marker>
					))}
				</GoogleMapReact>
			</Paper>
		</div>
	);
};

export default Map;
