import React, { useState, useEffect } from "react";

import { Box } from "@mui/material";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateRangeOutlined, CompareArrows, Clear } from "@mui/icons-material";
import { IconButton } from "@material-ui/core";

interface Props {
	range: DateRange<Date>;
	setRange: any;
	isSeparate?: boolean;
}

const RangePicker: React.FC<Partial<Props>> = ({
	range,
	setRange,
	isSeparate
}) => {
	const [value, setValue] = useState<any>(range);

	useEffect(() => {
		if (value) {
			setRange(value);
		}
	}, [value]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DateRangePicker
				value={value}
				clearable={true}
				disableFuture={true}
				inputFormat='dd/MM/yyyy'
				onChange={(newValue) => {
					setValue(newValue);
				}}
				renderInput={(startProps, endProps) => (
					<Box
						sx={{
							position: "relative",
							display: "flex",
							alignItems: "center",
							...(isSeparate
								? {
										width: 1,
										maxWidth: 320,
										justifyContent: "space-between"
								  }
								: {}),
							"& .input": {
								position: "relative",
								"& input": {
									width: 120,
									height: 36,
									pl: 4.75,
									pt: 0.25,
									color: "#443056",
									bgcolor: "background.default",
									border: "1px solid #00000030",
									borderRadius: "1px",
									fontSize: 13,
									":focus": {
										border: (theme) => "1px solid #443056",
										outline: "none"
									}
								},
								"& .icon": {
									position: "absolute",
									color: "#44305699",
									left: 8,
									top: 8
								},
								...(isSeparate
									? {}
									: {
											"& input.start": {
												borderRadius: "4px",

												borderColor: "none"
											},
											"& input.end": {
												borderRadius: "4px",

												borderColor: "none"
											}
									  })
							},
							...(!isSeparate
								? {
										"&:after": {
											content: '""',
											position: "absolute",
											left: 128,
											height: 22,
											width: "1px"
										}
								  }
								: {})
						}}
					>
						<div className='input'>
							<input
								className='start'
								placeholder='From'
								ref={
									startProps.inputRef as React.Ref<HTMLInputElement>
								}
								type='text'
								{...startProps.inputProps}
								style={{ marginRight: "3px" }}
							/>
							<DateRangeOutlined className='icon' />
						</div>
						{isSeparate && <CompareArrows />}
						<CompareArrows style={{ color: "#44305690" }} />
						<div className='input'>
							<input
								className='end'
								ref={
									endProps.inputRef as React.Ref<HTMLInputElement>
								}
								type='text'
								{...endProps.inputProps}
								style={{ marginLeft: "3px" }}
							/>
							<DateRangeOutlined className='icon' />
						</div>
					</Box>
				)}
			/>
		</LocalizationProvider>
	);
};

export default RangePicker;
