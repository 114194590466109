import {
  Avatar,
  Box,
  MenuItem,
  Select,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { ArrowCircleDown } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { isMaster } from "cluster";
import moment from 'moment'
import { getBookingData } from "../../../../../../services/BookingService";

const useStyles = makeStyles({
  count: {
    padding: "2px 8px 2px 8px",
    color: "#3CB99E",
    backgroundColor: "rgba(60, 185, 158, 0.14)",
    fontSize: "18px",
    fontWeight: 600,
    borderRadius: "10px",
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
});


interface EN_DATA {
  date: 'string';
  count?: number;
}
interface Props {
  bookingData: Array<EN_DATA>;
  setRange: (val: string) => void;
  range: string;
}
const Bookings: React.FC<Props> = ({ bookingData, setRange, range }) => {

  console.log(bookingData, 'booking data')

  // console.log(bookingData, 'booking-data')
  const theme = useTheme();
  const classes = useStyles();

  // const ranges = [{ id: "week", label: '3 Months' }, { id: "month", label: '1 Year' }];
  const types = [{id: 1, label: "actual"}, {id: 2, label: "difference"}];

  const [type, setType] = useState('actual');
  // let up = (ctx: any,value:string) => ctx.p0.parsed.y < ctx.p1.parsed.y ? value : undefined;
  // let down = (ctx: any,value:string) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

  // const bookingComparison = () => {
  //   let arr = [...bookingData]
  //   let comparison = arr.map(obj=>obj.date)
  // }

  const getLabel = () => {
    let arr = [...bookingData]
    let res = []
    res = arr.map(obj => {

      if (range === 'week') {
        const options: any = { month: 'short', day: 'numeric' };
        return (new Date(obj.date)).toLocaleDateString('en-US', options)
      }
      if (range === 'month') {
        const options: any = { month: 'short' };
        return (new Date(obj.date)).toLocaleDateString('en-US', options)
      }
    }) as unknown as Array<string>
    return res
  }

  const getValue = () => {
    let arr = [...bookingData]
    console.log(arr[0],'valueDiffBooking')
    let res = []
    res = arr.map(obj => obj.count)
    return res
  }

  const getValueDiff = () => {
    let arr = [...bookingData]
    let res = []
    res = arr.map(obj => obj.count) as Array<number>
    var newA = [];
    /*@ts-ignore*/
    for (var i = 0; i < res.length; i++)  newA.push(res[i] - res[i - 1])
    console.log(newA,'new array')
    return newA;
  }

  // const getStartDate = (range: string) => {
  //   switch (range) {
  //     // case 'Today':
  //     //   return moment().format('YYYY-MM-DD') as string
  //     case 'week':
  //       return moment().subtract(7, 'd').format('YYYY-MM-DD') as string
  //     case 'month':
  //       return moment().subtract(31, 'd').format('YYYY-MM-DD') as string
  //   }
  // }

  return (
    <Paper sx={{ boxShadow: "none", marginLeft: "-50px" }}>
      {bookingData.length > 0 ?
        <Box
          sx={{ p: 1 }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            mb={7}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex">
              <Typography variant="h6">Bookings</Typography>
              <div className={classes.count}>{`${bookingData && bookingData.length && bookingData[bookingData.length - 1].count || 0}`
              }</div>
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                width: "320px",
              }}
            >
              {/* <Box display="flex" sx={{ padding: "2px 7px 2px 7px" }}>
                <div style={{ color: "rgb(255, 150, 150)" }}>
                  <ArrowCircleDown />{" "}
                </div>
                <Typography> 21% from last week</Typography>
              </Box> */}
              <Select
                sx={{
                  backgroundColor: "rgb(245,245,245)",
                  height: "35px",
                }}
                className="primary"
                value={type}
                onChange={(e) => setType(e.target.value)}
                autoWidth
              >
                {types.map((range, i) => (
                    <MenuItem key={i} value={range.label}>
                        {range.label.toUpperCase()}
                    </MenuItem>
                ))}
              </Select>
            </div>
          </Box>
          <Box height="300px" width="95%">
            {/* <Box
              sx={{
                mb: 3,
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                justifyContent: "space-around",
                "& span": {
                  position: "relative",
                  fontSize: 13,
                  lineHeight: "1em",
                  color: 'grey',
                  "&:before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: -18,
                    height: 10,
                    width: 10,
                    backgroundColor: 'green',
                    borderRadius: 1,
                  },
                },
                "& .actual": {
                  ml: "18px",
                  mr: "58px",
                  "&:before": {
                    backgroundColor: '#538ADC',
                  },
                },
              }}
            >
              <span className="actual">Actual</span>
              <span className="difference">Difference</span>
            </Box> */}
            <Line
              data={(canvas) => {
                const ctx = canvas.getContext("2d");
                const g = ctx?.createLinearGradient(0, 0, 0, 290);

                g?.addColorStop(0, "rgba(255, 255, 255, 0.3)");
                // let labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
                // let labels = [...Array(10).keys()].map(String)
                let labels = getLabel()

                return {
                  labels,
                  datasets: [
                    {
                      // fill: true,
                      // yAxisID: "yAxis",
                      label: type,
                      data: type==='actual'? getValue():getValueDiff(),
                      borderColor: '#538ADC',
                      borderWidth: 2,
                      pointBackgroundColor: '#538ADC'
                      // backgroundColor: g,
                      // segment: {borderColor: ctx => up(ctx,'green') || down(ctx,'red')},
                      // tension: 0,
                      // pointRadius: 0,
                      // pointHoverRadius: 4,
                      // pointHoverBackgroundColor: "#fff",
                      // pointHoverBorderWidth: 3,
                    },
                    // {
                    //   // fill: true,
                    //   // yAxisID: "yAxis2",
                    //   label: 'Difference',
                    //   data: getValueDiff(),
                    //   borderColor: 'green',
                    //   borderWidth: 2,
                    //   pointBackgroundColor: 'green'
                    //   // backgroundColor: g,
                    //   // segment: {borderColor: ctx => up(ctx,'green') || down(ctx,'red')},
                    //   // tension: 0,
                    //   // pointRadius: 0,
                    //   // pointHoverRadius: 4,
                    //   // pointHoverBackgroundColor: "#fff",
                    //   // pointHoverBorderWidth: 3,
                    // }
                    // {
                    //   yAxisID: "yAxis2",
                    //   data: getValueDiff(),
                    //   borderColor: 'green',
                    //   borderWidth: 2,
                    //   pointBackgroundColor: 'green'
                    // },
                  ],
                };
              }}
              options={{
                scales: {
                  xAxis: {
                    // type: 'time',
                    grid: {
                      display: false,
                      tickWidth: 0,
                      tickLength: 16,
                      drawBorder: false,
                    },
                    ticks: {
                      color: theme.palette.text.secondary,
                    },
                  },
                  yAxis: {
                    title: {
                      display: true,
                      // text: "No. of Bookings",
                      padding: {
                        top: 0,
                        bottom: 8,
                      },
                      color: "grey",
                      font: {
                        weight: "500",
                        size: 12,
                      },
                    },
                    ticks: {
                      color: theme.palette.text.secondary,
                     },
                    suggestedMin: 0,
                    grid: {
                      borderDash: [10],
                      tickWidth: 0,
                      tickLength: 16,
                      drawBorder: true,
                    },
                  },
                  // yAxis2: {
                  //   position:'right',
                  //   title: {
                  //     display: true,
                  //     text: "Difference",
                  //     padding: {
                  //       top: 0,
                  //       bottom: 8,
                  //     },
                  //     color: "grey",
                  //     font: {
                  //       weight: "500",
                  //       size: 12,
                  //     },
                  //   },
                  //   ticks: {
                  //     color: theme.palette.text.secondary,
                  //   },
                  //   suggestedMin: 0,
                  //   grid: {
                  //     // display: false,
                  //     borderDash: [10],
                  //     tickWidth: 0,
                  //     tickLength: 16,
                  //     drawBorder: true,
                  //   },
                  // },
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    caretSize: 0,
                    mode: "index",
                    intersect: false,
                    yAlign: "center",
                    displayColors: false,
                    caretPadding: 16,
                    titleFont: {
                      weight: "400",
                    },
                    bodyFont: {
                      weight: "500",
                    },
                  },
                },
                interaction: {
                  mode: "index",
                  intersect: false,
                },
              }}
            />
          </Box>
        </Box> : 'Loading'}
    </Paper>
  );
}

export default Bookings;
