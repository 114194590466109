import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Drawer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  ImageList,
  ImageListItem,
  Button,
  TextField,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import ChevronRight from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import TrackKYC from "./TrackKYC";
import { DatePicker } from "@mui/lab";
import { AuthContext } from "../../../context/auth";

interface InfoProps {
  open: boolean;
  data: any;
  handleOpen: () => void;
  handleClose: () => void;
  handleFunc: () => void;
}

const InfoDrawer: React.FC<InfoProps> = ({
  open,
  data,
  handleClose,
  handleOpen,
  handleFunc,
}) => {
  const context: any = useContext(AuthContext);

  const [pic, setPic] = useState<any>("");
  const [remark, setRemark] = useState<any>("");

  const [shippingDate, setShippingDate] = useState<any>("");
  const [shippingId, setShippingId] = useState<any>("");
  const [shippingProvider, setShippingProvider] = useState<any>("");
  const [qrCode, setQrCode] = useState<any>("");

  const [comment, setComment] = useState<any>("");

  const addComment = () => {
    if (!comment) {
      alert("Please enter a comment");
      return;
    }
    const kycData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userPhone: infoData.phone,
        comment,
      }),
    };
    fetch(`https://marketing.revos.in/addComment`, kycData)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });
    handleFunc();
    handleClose();
  };

  const [infoData, setInfoData] = useState({
    lastUpdatedAt: "",
    utmCampaign: "",
    utmMedium: "",
    utmSource: "",
    kycId: "",
    kycStatus: "",
    purpose: "",
    haveEv: "",
    pictureUrls: [],
    kycUpdatedAt: "",
    orderId: "",
    orderStatus: "",
    amount: "",
    trackingId: "",
    firstName: "",
    lastName: "",
    trackStatus: "",
    kycReminders: 0,
    inappropriateStrikes: 0,
    kycRemarks: [],
    userComments: [],
    shippingId: "",
    shippingDate: "",
    shippingProvider: "",
    qrCode: "",
    phone: "",
    email: "",
    address: "",
    landmark: "",
    postal: "",
    city: "",
    state: "",
    latitude: "",
    longitude: "",
    refundId: "",
    refundStatus: "",
  });

  useEffect(() => {
    if (open) {
      setInfoData({
        lastUpdatedAt: data.user.updatedAt,
        utmCampaign: data.user.utmCampaign,
        utmMedium: data.user.utmMedium,
        utmSource: data.user.utmSource,
        kycId: data.kyc?.kycId,
        kycStatus: data.kyc?.kycStatus,
        purpose: data.kyc?.purpose,
        haveEv: data.kyc?.haveEv,
        pictureUrls: data.kyc?.pictureUrls ? data.kyc.pictureUrls : [],
        kycUpdatedAt: data.kyc?.updatedAt,
        orderId: data.order.orderId,
        orderStatus: data.order.orderStatus,
        amount: data.order.amount,
        trackingId: data.order.trackingId,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        trackStatus: data.user.trackStatus,
        kycReminders: data.user.kycReminders,
        inappropriateStrikes: data.user.inappropriateStrikes,
        kycRemarks:
          data.kyc?.kycRemarks?.length > 0
            ? data.kyc?.kycRemarks.sort((a: any, b: any) => {
                return (
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
                );
              })
            : [],
        userComments: data.user.userComments.sort((a: any, b: any) => {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        }),
        shippingId: data.user.shippingId,
        shippingDate: data.user.shippingDate,
        shippingProvider: data.user.shippingProvider,
        qrCode: data.user.qrCode,
        phone: data.user.phone,
        email: data.user.email,
        address: data.user.address,
        landmark: data.user.landmark,
        postal: data.user.postalCode,
        city: data.user.city,
        state: data.user.state,
        latitude: data.user.latitude,
        longitude: data.user.longitude,
        refundId: data.order.refundId,
        refundStatus: data.order.refundStatus,
      });
      setFirstName(data.user.firstName);
      setLastName(data.user.lastName);
      setEmail(data.user.email);
      setAddress(data.user.address);
      setLandmark(data.user.landmark);
      setPostalCode(data.user.postalCode);
      setCity(data.user.city);
      setState(data.user.state);
    }
  }, [open, data]);
  const useStyles = makeStyles((theme) => ({
    paper: {
      marginLeft: "10px",
      maxWidth: "100%",
      width: "70%",
    },
    table: {
      width: "100%",
      marginBottom: 20,
    },
    // tableCell: {
    //   width: "10%",
    // },
  }));
  const classes = useStyles();
  function createRow(label: any, value: any) {
    return (
      <TableRow>
        <TableCell style={{ width: "20%" }}>{label}</TableCell>
        <TableCell style={{ width: "80%" }}>{value}</TableCell>
      </TableRow>
    );
  }

  const onApprove = () => {
    const kycData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        kycId: infoData.kycId,
        kycStatus: "APPROVED",
      }),
    };
    fetch(`https://marketing.revos.in/updateKycStatus`, kycData)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert(data.message);
      });

    handleFunc();
    handleClose();
  };

  const onInappropriate = () => {
    const kycData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        kycId: infoData.kycId,
        kycStatus: "INAPPROPRIATE",
        remark,
      }),
    };
    fetch(`https://marketing.revos.in/updateKycStatus`, kycData)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert(data.message);
      });
    handleFunc();
    handleClose();
  };

  const onReject = () => {
    if (!remark) {
      alert("Please enter remark");
      return;
    }
    const kycData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userPhone: infoData.phone,
        action: "REJECT",
        remark,
      }),
    };
    fetch(`https://marketing.revos.in/processOrder`, kycData)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert(data.message);
      });
    handleFunc();
    handleClose();
  };

  const onRemind = async () => {
    const phoneData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recepientPhones: [infoData.phone],
      }),
    };
    fetch(`https://marketing.revos.in/pushStatus/reminder`, phoneData)
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "at reminder");
        // setSuccessDialog({ ...successDialog, status: data[0].status });
        alert(data.message);
        // console.log(data.message, "remind kyc display message on dashboard")
      });

    // const emailData = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     recepientEmails: [infoData.email],
    //   }),
    // };
    // fetch(
    //   `https://marketing.revos.in/pushStatus/kycPending/email`,
    //   emailData
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data);
    //   });
    handleFunc();
    handleClose();
  };

  const onDispatch = () => {
    if (!shippingDate || !shippingProvider || !shippingId || !qrCode) {
      alert("Please enter all details");
      return;
    }
    const kycData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userPhone: infoData.phone,
        action: "DISPATCH",
        shippingDate: shippingDate,
        shippingId: shippingId,
        shippingProvider: shippingProvider,
        qrCode,
      }),
    };
    fetch(`https://marketing.revos.in/processOrder`, kycData)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert(data.message);
      });
    handleFunc();
    handleClose();
  };

  const onDuplicate = () => {
    const trackStatus = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userPhone: infoData.phone,
        action: "DUPLICATE",
      }),
    };
    fetch(`https://marketing.revos.in/updateTrackStatus`, trackStatus)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert(data.message);
      });
    handleFunc();
    handleClose();
  };

  const onShortlist = () => {
    const trackStatus = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userPhone: infoData.phone,
        action: "SHORTLIST",
      }),
    };
    fetch(`https://marketing.revos.in/updateTrackStatus`, trackStatus)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert(data.message);
      });
    handleFunc();
    handleClose();
  };

  const onDelivered = () => {
    const trackStatus = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userPhone: infoData.phone,
        action: "DELIVERED",
      }),
    };
    fetch(`https://marketing.revos.in/updateTrackStatus`, trackStatus)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert(data.message);
      });
    handleFunc();
    handleClose();
  };

  const onInstalled = () => {
    const trackStatus = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userPhone: infoData.phone,
        action: "INSTALLED",
      }),
    };
    fetch(`https://marketing.revos.in/updateTrackStatus`, trackStatus)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert(data.message);
      });
    handleFunc();
    handleClose();
  };

  const onInitialised = () => {
    const trackStatus = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userPhone: infoData.phone,
        action: "INITIALISED",
      }),
    };
    fetch(`https://marketing.revos.in/updateTrackStatus`, trackStatus)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert(data.message);
      });
    handleFunc();
    handleClose();
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [editMode, setEditMode] = useState(false);

  const saveUserDetails = async () => {
    const userData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: data.user.phone,
        firstName,
        lastName,
        email,
        address,
        landmark,
        city,
        state,
        postalCode,
      }),
    };

    const saveResponse = await fetch(
      `https://marketing.revos.in/user/updateUser`,
      userData
    );
    const saveData = await saveResponse.json();
    console.log(saveData);

    if (saveData.status !== 200) {
      alert(saveData.message);
      setFirstName(data.user.firstName);
      setLastName(data.user.lastName);
      setEmail(data.user.email);
      setAddress(data.user.address);
      setLandmark(data.user.landmark);
      setPostalCode(data.user.postalCode);
      setCity(data.user.city);
      setState(data.user.state);
    }

    setEditMode(!editMode);
  };

  return (
    <>
      {pic && (
        <div
          className="outerDiv"
          onClick={(e: any) => {
            if (e.target.classList.contains("outerDiv")) {
              setPic("");
              handleOpen();
            }
          }}
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100,
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="innerDiv"
            style={{
              zIndex: 200,
              position: "relative",
              display: "flex",
              marginTop: "50px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "20px",
            }}
          >
            <img
              src={pic}
              alt="pic"
              style={{
                maxHeight: "80vh",
                minWidth: "200px",
                maxWidth: "80vw",
                borderRadius: "20px",
              }}
            />
          </div>
        </div>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <Box
          ml={1}
          mt={0.2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h5"
            style={{ marginLeft: "8px", fontWeight: "bold" }}
          >
            Info
          </Typography>
          <IconButton onClick={handleClose}>
            <ChevronRight />
          </IconButton>
        </Box>
        <Table
          size="small"
          className={classes.table}
          style={{ margin: "8px 0" }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <h3>User Info</h3>
                {(context.user.email === "marketing@revos.in" ||
                  context.user.email === "taijas@bolt.earth" ||
                  context.user.userId === "618bd59eeb097a000885a169") && (
                  <Button
                    variant="contained"
                    disableElevation
                    style={{
                      background: "#24a0ed",
                      color: "white",
                      margin: "5px",
                    }}
                    onClick={() =>
                      editMode ? saveUserDetails() : setEditMode(!editMode)
                    }
                  >
                    {editMode ? "Save" : "Edit"}
                  </Button>
                )}
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    background: "#24a0ed",
                    color: "white",
                    margin: "5px",
                  }}
                  onClick={handleFunc}
                >
                  Refresh
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {createRow(
              "Last Updated At",
              <p>{`${new Date(infoData.lastUpdatedAt).toLocaleString()}`}</p>
            )}
          </TableBody>
          <TableBody>
            {createRow("Tracking Status", infoData.trackStatus)}
          </TableBody>
          <TableBody>
            {createRow(
              "First Name",
              editMode ? (
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              ) : (
                firstName
              )
            )}
          </TableBody>
          <TableBody>
            {createRow(
              "Last Name",
              editMode ? (
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              ) : (
                lastName
              )
            )}
          </TableBody>
          <TableBody>{createRow("Phone Number", infoData.phone)}</TableBody>
          <TableBody>
            {createRow(
              "Email",
              editMode ? (
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              ) : (
                email
              )
            )}
          </TableBody>
          <TableBody>
            {createRow(
              "Address",
              editMode ? (
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              ) : (
                address
              )
            )}
          </TableBody>
          <TableBody>
            {createRow(
              "Landmark",
              editMode ? (
                <input
                  type="text"
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                />
              ) : (
                landmark
              )
            )}
          </TableBody>
          <TableBody>
            {createRow(
              "Postal Code",
              editMode ? (
                <input
                  type="text"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              ) : (
                postalCode
              )
            )}
          </TableBody>
          <TableBody>
            {createRow(
              "City",
              editMode ? (
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              ) : (
                city
              )
            )}
          </TableBody>
          <TableBody>
            {createRow(
              "State",
              editMode ? (
                <input
                  type="text"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              ) : (
                state
              )
            )}
          </TableBody>
          <TableBody>
            {createRow("Latitude", <p>{`${infoData.latitude}`}</p>)}
          </TableBody>
          <TableBody>
            {createRow("Longitude", <p>{`${infoData.longitude}`}</p>)}
          </TableBody>
          <TableBody>
            {createRow(
              "Comments",
              infoData.userComments.map((item: any) => {
                return (
                  <div
                    key={item.kycRemarkId}
                    style={{
                      margin: "5px",
                    }}
                  >
                    {new Date(item.createdAt).toLocaleString()}
                    <br />
                    {item.comment}
                    <br />
                  </div>
                );
              })
            )}
          </TableBody>
          <TableBody>
            {createRow(
              "Add Comment",
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  placeholder="Comment"
                  style={{
                    width: "100%",
                    border: "1px solid #d3d3d3",
                    borderRadius: "5px",
                    padding: "5px",
                    margin: "5px",
                  }}
                  onChange={(e) => setComment(e.target.value)}
                />
                <Button
                  variant="contained"
                  style={{
                    background: "#61D069",
                    color: "white",
                  }}
                  disableElevation
                  onClick={addComment}
                >
                  Add Comment
                </Button>
              </div>
            )}
          </TableBody>
        </Table>

        <Table
          size="small"
          className={classes.table}
          style={{ margin: "8px 0" }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <h3>Order Info</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{createRow("Order Id", infoData.orderId)}</TableBody>
          <TableBody>
            {createRow("Order Status", infoData.orderStatus)}
          </TableBody>
          <TableBody>{createRow("Amount", `₹ ${infoData.amount}`)}</TableBody>
          <TableBody>{createRow("Tracking Id", infoData.trackingId)}</TableBody>
          <TableBody>{createRow("Refund Id", infoData.refundId)}</TableBody>
          <TableBody>
            {createRow("Refund Status", infoData.refundStatus)}
          </TableBody>
        </Table>

        <Table
          size="small"
          className={classes.table}
          style={{ margin: "8px 0" }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <h3>KYC Info</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {createRow(
              "Last Updated At",
              <p>
                {infoData.kycUpdatedAt &&
                  `${new Date(infoData.kycUpdatedAt).toLocaleString()}`}
              </p>
            )}
          </TableBody>
          <TableBody>{createRow("Purpose", infoData.purpose)}</TableBody>
          <TableBody>{createRow("KYC Status", infoData.kycStatus)}</TableBody>
          <TableBody>{createRow("Have EV", infoData.haveEv)}</TableBody>
          <TableBody>
            {createRow("Kyc Reminders", infoData.kycReminders)}
          </TableBody>
          <TableBody>
            {createRow("Inappropriate Strikes", infoData.inappropriateStrikes)}
          </TableBody>
          {(context.user.email === "marketing@revos.in" ||
            context.user.email === "taijas@bolt.earth" ||
            context.user.userId === "618bd59eeb097a000885a169") && (
            <TableBody>
              {createRow(
                "Pictures",
                <ImageList cols={2} gap={8}>
                  {infoData.pictureUrls.map((item) => (
                    <ImageListItem key={item}>
                      <img
                        src={`${item}?w=248&fit=crop&auto=format`}
                        srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={item}
                        loading="lazy"
                        style={{
                          borderRadius: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPic(item);
                          handleClose();
                        }}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              )}
            </TableBody>
          )}
          <TableBody>
            {createRow(
              "Kyc Remarks",
              infoData.kycRemarks.map((item: any) => (
                <div
                  key={item.kycRemarkId}
                  style={{
                    margin: "5px",
                  }}
                >
                  {new Date(item.createdAt).toLocaleString()}
                  <br />
                  {item.remark}
                </div>
              ))
            )}
          </TableBody>
          <TableBody>
            {createRow("Shipping Date", infoData.shippingDate)}
          </TableBody>
          <TableBody>{createRow("Shipping Id", infoData.shippingId)}</TableBody>
          <TableBody>
            {createRow("Shipping Provider", infoData.shippingProvider)}
          </TableBody>
          <TableBody>{createRow("BOLT QR Code", infoData.qrCode)}</TableBody>
          <TableBody>
            {createRow(
              "Actions",
              <div>
                {(context.user.email === "marketing@revos.in" ||
                  context.user.email === "taijas@bolt.earth" ||
                  context.user.userId === "618bd59eeb097a000885a169") &&
                  infoData.trackStatus === "CHARGER_INSTALLED" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        disableElevation
                        style={{
                          background: "#61D069",
                          color: "white",
                        }}
                        onClick={onInitialised}
                      >
                        Initialised
                      </Button>
                      {/* <div
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center"
												}}
											>
												<input
													type='text'
													placeholder='Remark'
													style={{
														width: "100%",
														border: "1px solid #d3d3d3",
														borderRadius: "5px",
														padding: "5px",
														margin: "5px"
													}}
													onChange={(e) =>
														setRemark(
															e.target.value
														)
													}
												/>
												<Button
													variant='contained'
													style={{
														background: "#d06161",
														color: "white"
													}}
													disableElevation
													onClick={onReject}
												>
													Refund
												</Button>
											</div> */}
                    </div>
                  )}
                {(context.user.email === "marketing@revos.in" ||
                  context.user.email === "taijas@bolt.earth" ||
                  context.user.userId === "618bd59eeb097a000885a169") &&
                  infoData.trackStatus === "ORDER_DELIVERED" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        disableElevation
                        style={{
                          background: "#61D069",
                          color: "white",
                        }}
                        onClick={onInstalled}
                      >
                        Installed
                      </Button>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Remark"
                          style={{
                            width: "100%",
                            border: "1px solid #d3d3d3",
                            borderRadius: "5px",
                            padding: "5px",
                            margin: "5px",
                          }}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          style={{
                            background: "#d06161",
                            color: "white",
                          }}
                          disableElevation
                          onClick={onReject}
                        >
                          Refund
                        </Button>
                      </div>
                    </div>
                  )}
                {(context.user.email === "marketing@revos.in" ||
                  context.user.email === "taijas@bolt.earth" ||
                  context.user.userId === "618bd59eeb097a000885a169") &&
                  infoData.trackStatus === "ORDER_DISPATCHED" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        disableElevation
                        style={{
                          background: "#61D069",
                          color: "white",
                        }}
                        onClick={onDelivered}
                      >
                        Delivered
                      </Button>
                      {/* <div
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center"
												}}
											>
												<input
													type='text'
													placeholder='Remark'
													style={{
														width: "100%",
														border: "1px solid #d3d3d3",
														borderRadius: "5px",
														padding: "5px",
														margin: "5px"
													}}
													onChange={(e) =>
														setRemark(
															e.target.value
														)
													}
												/>
												<Button
													variant='contained'
													style={{
														background: "#d06161",
														color: "white"
													}}
													disableElevation
													onClick={onReject}
												>
													Refund
												</Button>
											</div> */}
                    </div>
                  )}
                {(context.user.email === "marketing@revos.in" ||
                  context.user.email === "taijas@bolt.earth" ||
                  context.user.userId === "618bd59eeb097a000885a169") &&
                  infoData.kycStatus === "SUBMITTED" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        disableElevation
                        style={{
                          background: "#61D069",
                          color: "white",
                        }}
                        onClick={onDuplicate}
                      >
                        Duplicate
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        style={{
                          background: "#61D069",
                          color: "white",
                        }}
                        onClick={onShortlist}
                      >
                        Shortlist
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        style={{
                          background: "#61D069",
                          color: "white",
                        }}
                        onClick={onApprove}
                      >
                        Approve
                      </Button>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Remark"
                          style={{
                            width: "100%",
                            border: "1px solid #d3d3d3",
                            borderRadius: "5px",
                            padding: "5px",
                            margin: "5px",
                          }}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          style={{
                            background: "#d06161",
                            color: "white",
                          }}
                          disableElevation
                          onClick={onInappropriate}
                        >
                          Inappropriate
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Remark"
                          style={{
                            width: "100%",
                            border: "1px solid #d3d3d3",
                            borderRadius: "5px",
                            padding: "5px",
                            margin: "5px",
                          }}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          style={{
                            background: "#d06161",
                            color: "white",
                          }}
                          disableElevation
                          onClick={onReject}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  )}

                {(context.user.email === "marketing@revos.in" ||
                  context.user.email === "taijas@bolt.earth" ||
                  context.user.userId === "618bd59eeb097a000885a169" ||
                  context.user.email === "courier@revos.in" ||
                  context.user.email === "taijas@bolt.earth" ||
                  context.user.userId === "61a34c81f42ea00007fbc17d") &&
                  infoData.trackStatus === "KYC_APPROVED" && (
                    <div>
                      <Button
                        variant="contained"
                        disableElevation
                        style={{
                          background: "#61D069",
                          color: "white",
                          marginBottom: "5px",
                        }}
                        onClick={onDuplicate}
                      >
                        Duplicate
                      </Button>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <input
                          type="datetime-local"
                          value={shippingDate}
                          onChange={(e) => setShippingDate(e.target.value)}
                          style={{
                            marginBottom: "10px",
                            height: "35px",
                          }}
                        />
                        <input
                          type="text"
                          value={shippingProvider}
                          placeholder="Shipping Provider"
                          onChange={(e) => setShippingProvider(e.target.value)}
                          style={{
                            marginBottom: "10px",
                            height: "35px",
                          }}
                        />
                        <input
                          type="text"
                          value={shippingId}
                          placeholder="Shipping Id"
                          onChange={(e) => setShippingId(e.target.value)}
                          style={{
                            marginBottom: "10px",
                            height: "35px",
                          }}
                        />
                        <input
                          type="text"
                          value={qrCode}
                          placeholder="BOLT QR Code"
                          onChange={(e) => setQrCode(e.target.value)}
                          style={{
                            marginBottom: "10px",
                            height: "35px",
                          }}
                        />
                        <Button
                          variant="contained"
                          disableElevation
                          style={{
                            background: "#61D069",
                            color: "white",
                          }}
                          onClick={onDispatch}
                        >
                          Dispatch
                        </Button>
                      </div>
                    </div>
                  )}

                {(((context.user.email === "marketing@revos.in" ||
                  context.user.userId === "618bd59eeb097a000885a169" ||
                  context.user.email === "taijas@bolt.earth" ||
                  context.user.email === "operations@revos.in" ||
                  context.user.userId === "6198f5cf30fb8c0007aed17a") &&
                  infoData.kycStatus === "PENDING") ||
                  !infoData.kycStatus ||
                  infoData.kycStatus === "INAPPROPRIATE") &&
                  infoData.orderStatus === "PAID" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        disableElevation
                        style={{
                          background: "#61D069",
                          color: "white",
                        }}
                        onClick={onRemind}
                      >
                        Remind KYC
                      </Button>
                      {(context.user.email === "marketing@revos.in" ||
                        context.user.email === "taijas@bolt.earth" ||
                        context.user.userId === "618bd59eeb097a000885a169") && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Remark"
                            style={{
                              width: "100%",
                              border: "1px solid #d3d3d3",
                              borderRadius: "5px",
                              padding: "5px",
                              margin: "5px",
                            }}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                          <Button
                            variant="contained"
                            style={{
                              background: "#d06161",
                              color: "white",
                            }}
                            disableElevation
                            onClick={onReject}
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            )}
          </TableBody>
        </Table>

        <Table
          size="small"
          className={classes.table}
          style={{ margin: "8px 0" }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <h3>UTM Info</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {createRow("UTM Campaign", infoData.utmCampaign)}
          </TableBody>
          <TableBody>{createRow("UTM Medium", infoData.utmMedium)}</TableBody>
          <TableBody>{createRow("UTM Source", infoData.utmSource)}</TableBody>
        </Table>
      </Drawer>
    </>
  );
};

export default InfoDrawer;
