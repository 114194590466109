import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Onboard from "./components/pages/login-signup/Onboard";
import { AuthContext, AuthProvider } from "./components/context/auth";
import { PrivateRoute, AppRoute } from "./components/config/routing/authRoute";
import { ThemeProvider } from "@material-ui/core";
import theme from "./components/styles/GlobalTheme";
 function App() {
  const context = useContext(AuthContext);
  // console.log(context.user);
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <PrivateRoute path="/app" component={Dashboard} />
            <AppRoute path="/onboard" component={Onboard} />
            {/* <Redirect to="/onboard" /> */}
          </Switch>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
