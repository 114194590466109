export const getEarningData = async (type, range) => {
    try {
    // let url = `https://datafeed.revos.in/housekeeping/${type}/bookings?token=1234&id=${id}&start=${start_date}&end=${end_date}`;
    let url = `https://datafeed.revos.in/housekeeping/${type}/earnings?token=1234&timeframe=${range}`
    // if (isMaster) url = url + "&master=true";
     let res = await fetch(url, {
      method: "GET",
    })
      res = await res.json();
      // console.log(res,'at booking service')
      return res;
      // type==='bolt'?res:res.data;
      } catch (e){
         console.log(e)
         return {data: []}
      }
  };
  