import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// function createData(name: any,
//     calories: any,
//     fat: any,
// ) {
//     return { name, calories, fat };
// }

// const rows = [
//     createData('Frozen ice', 159, 6.0),
//     createData('Ice cream', 237, 9.0),
//     createData('Eclair', 262, 16.0),
//     createData('Cupcake', 305, 3.7),
//     createData('Gingerbread', 356, 16.0),
// ];



const ApiTable: React.FC = () => {
    const [alignment, setAlignment] = useState('web');

    const handleChange = (event: any, alignment: any) => {
        setAlignment(alignment);
    };

     const [totalApis, setTotalApiData] = useState<Array<any>>([])
 
    useEffect(() => {
        if (alignment === "web") {
            apiTotalData()
        } else if (alignment === "android") {
            apiAppData()
        } else {
            apiDashboardData()
        }
    }, [alignment])

    const apiTotalData = async () => {
        try {
            let url = 'https://datafeed.dev.revos.in/service/bolt/stats/topOverallAPIs'
            let res = await fetch(url, {
                headers: { token: '1234' },
                method: "GET",
            })
            let response = await res.json() as Array<any>;
            setTotalApiData(response as Array<any>)
        }
        catch (e) {
            return e
        }
    }

    const apiAppData = async () => {
        try {
            let url = 'https://datafeed.dev.revos.in/service/bolt/stats/topAppAPIs'
            let res = await fetch(url, {
                headers: { token: '1234' },
                method: "GET",
            })
            let response = await res.json() as Array<any>;
            setTotalApiData(response as Array<any>)
        }
        catch (e) {
            return e
        }
    }

    const apiDashboardData = async () => {
        try {
            let url = 'https://datafeed.dev.revos.in/service/bolt/stats/topCompanyAPIs'
            let res = await fetch(url, {
                headers: { token: '1234' },
                method: "GET",
            })
            let response = await res.json() as Array<any>;
            setTotalApiData(response as Array<any>)
        }
        catch (e) {
            return e
        }
    }

    return (
        <div>
            <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                sx={{ minWidth: 100, height: "35px" }}
            >
                <ToggleButton value="web">OverAll</ToggleButton>
                <ToggleButton value="android">App</ToggleButton>
                <ToggleButton value="ios">Companies</ToggleButton>
            </ToggleButtonGroup>
            <br />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 610, marginTop: '25px' }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>API</TableCell>
                            <TableCell align="center">API Exec. Time (s)</TableCell>
                            <TableCell align="center">Total Calls</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {totalApis.map((row) => (
                            <TableRow
                                key={row.api}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" align='center'>
                                    {row.api}
                                </TableCell>
                                <TableCell align="center">{row.avgExecTime}</TableCell>
                                <TableCell align="center">{row.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default ApiTable
