import React, { FC, useState, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import SearchBar from "../../../global/SearchBar/index";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .column-header": {
      fontSize: "13px",
    },
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Company = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [usersDialog, setUsersDialog] = useState({
    open: false,
    data: {},
  });
  const [rerender, setReRender] = useState(true);
  const [editDialog, setEditDialog] = useState({ open: false, data: {} });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    data: {},
  });
  const FEED_QUERY = gql`
    {
      companyQ {
        getAllCompanies {
          id
          name
          type
          status
          createdAt
          users {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  `;

  const { data, refetch } = useQuery(FEED_QUERY);

  useEffect(() => {
    if (data) {
      setRows(data.companyQ.getAllCompanies);
      setLoading(false);
      console.log(data.companyQ.getAllCompanies);
    }
    setSearch("");
  }, [data]);

  useEffect(() => {
    let chargeSearch = rows;
    if (search) {
      chargeSearch = chargeSearch.filter(({ name, type, status }) =>
        [name, type, status].some(
          (el) => el && String(el).toLowerCase().includes(search.toLowerCase())
        )
      );
      setRows(chargeSearch);
    }
  }, [search]);

  const classes = useStyles();

  const refresh = () => {
    setLoading(true);
    setRows(data.getAllCompanies);
    setLoading(false);
  };

  const handleCloseEdit = () => {
    setEditDialog({ ...editDialog, open: false });
  };
  const handleCloseUsers = () => {
    setUsersDialog({ ...usersDialog, open: false });
  };

  return (
    <Paper
      elevation={3}
      style={{ padding: 12, margin: 0, border: "0.2px solid #44305660" }}
    >
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" style={{ marginLeft: "5px" }}>
          Company
        </Typography>
        <Box display="flex" alignItems="center">
          <SearchBar
            handleSearch={setSearch}
            persist
            enableClear={Boolean(search)}
            disableFlex
            placeholder="Search..."
            id="1"
          />
        </Box>
        {/* <Button
          variant="contained"
          disableElevation
          style={{ marginRight: '5px' }}
          startIcon={<AddIcon />}
        >
          New
        </Button> */}
      </Box>
      <Box height={"80vh"} className={classes.root}>
        <DataGrid
          rows={rows || []}
          columns={[
            {
              field: "company name",
              headerName: "Company Name",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <Box style={{ marginLeft: "5px" }}>{params.row.name}</Box>
              ),
            },
            {
              field: "created at",
              headerName: "Created At",
              flex: 1,
              valueFormatter: (params) =>
                moment(params.row.createdAt).format("ddd, MMM DD, YYYY"),
            },
            {
              field: "type",
              headerName: "Type",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <Box style={{ marginLeft: "5px" }}>{params.row.type}</Box>
              ),
            },
            {
              field: "status",
              headerName: "Status",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <Box style={{ marginLeft: "5px" }}>{params.row.status}</Box>
              ),
            },
            {
              field: "users",
              headerName: "Users",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setUsersDialog({ open: true, data: params.row });
                    }}
                    variant="outlined"
                    size="small"
                    disableElevation
                    style={{ marginRight: "10px" }}
                  >
                    View
                  </Button>
                </>
              ),
            },

            {
              field: "actions",
              headerName: "Actions",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setEditDialog({ open: true, data: params.row });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton
                    onClick={() =>
                      setDeleteDialog({ open: true, data: params.row })
                    }
                  >
                    <DeleteIcon />
                  </IconButton> */}
                </>
              ),
            },
          ]}
          rowHeight={48}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          loading={loading}
          disableSelectionOnClick
        />
        <EditDialog
          open={editDialog.open}
          data={editDialog.data}
          handleClose={handleCloseEdit}
          FEED_QUERY={FEED_QUERY}
          refetch={refetch}
          refresh={refresh}
          setLoading={setLoading}
        />
        <UsersDialog
          open={usersDialog.open}
          handleClose={handleCloseUsers}
          data={usersDialog.data}
        />
      </Box>
    </Paper>
  );
};

interface EditDialogProps {
  open: boolean;
  data: any;
  handleClose: () => void;
  FEED_QUERY: any;
  refetch: any;
  refresh: any;
  setLoading: any;
}

const EditDialog: React.FC<EditDialogProps> = ({
  open,
  data,
  handleClose,
  FEED_QUERY,
}) => {
  const [editData, setEditData] = useState({
    status: "",
    type: "",
  });

  useEffect(() => {
    if (open) {
      setEditData({ status: data.status, type: data.type || "None" });
    } else {
      setEditData({ status: "", type: "" });
    }
  }, [data]);

  function handleChange(key: any, value: any) {
    setEditData((prevInput) => ({ ...prevInput, [key]: value }));
  }

  console.log(data.status);

  const UPDATE_STATUS = gql`
    mutation UpdateStatus(
      $updateCompanyStatusWhere: CompanyWhereInput
      $updateCompanyStatusData: CompanyInput
    ) {
      companyM {
        updateCompanyStatus(
          where: $updateCompanyStatusWhere
          data: $updateCompanyStatusData
        ) {
          status
        }
      }
    }
  `;

  const [updateStatus] = useMutation(UPDATE_STATUS, {
    refetchQueries: [{ query: FEED_QUERY }],
  });

  const saveData = (e: any) => {
    e.preventDefault();
    // setLoading(true);
    updateStatus({
      variables: {
        updateCompanyStatusWhere: { id: data.id },
        updateCompanyStatusData: editData,
      },
    });

    // refresh();
    // refetch();

    handleClose();
  };

  const statusList = ["ACTIVE", "INACTIVE"];

  console.log(editData);

  const typeList = [
    "BASIC",
    "DISTRIBUTOR",
    "OEM",
    " DEALER",
    "RIDE_SHARING",
    "BOLT_DISTRIBUTOR",
  ];

  const classes = useStyles();
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">Edit Company</Typography>
        <Typography style={{ fontSize: "0.8rem" }} variant="h6">
          {data.name}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          margin="0 auto"
          marginBottom="30px"
          style={{ marginTop: "30px" }}
          width={450}
          display="grid"
          gridGap={16}
          gridTemplateColumns="3fr 6fr"
          alignItems="end"
        >
          <Typography style={{ marginBottom: "20px" }} gutterBottom>
            Status
          </Typography>
          <FormControl
            variant="outlined"
            required
            className={classes.formControl}
            style={{ outline: "none", marginTop: "30px" }}
          >
            <Select
              native
              onChange={(e) => {
                handleChange("status", e.target.value);
              }}
              name="status"
              defaultValue={data.status}
            >
              <option value={data.status}>{data.status}</option>
              {statusList.map((item, index) => {
                if (item === data.status) {
                  return "";
                } else {
                  return <option value={item}>{item}</option>;
                }
              })}
            </Select>
          </FormControl>
          <Typography style={{ marginBottom: "20px" }} gutterBottom>
            Type
          </Typography>
          <FormControl
            variant="outlined"
            required
            className={classes.formControl}
            style={{ outline: "none", marginTop: "30px" }}
          >
            <Select
              native
              onChange={(e) => {
                handleChange("type", e.target.value);
              }}
              name="status"
              defaultValue={data.type}
            >
              <option value={data.type}>{data.type}</option>
              {typeList.map((item, index) => {
                if (item === data.type) {
                  return "";
                } else {
                  return <option value={item}>{item}</option>;
                }
              })}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={saveData}
          style={{
            width: "150px",
            margin: "20px",
            height: "40px",
            padding: "25px",
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface UsersDialogProps {
  open: boolean;
  data: any;
  handleClose: () => void;
}

const UsersDialog: React.FC<UsersDialogProps> = ({
  open,
  data,
  handleClose,
}) => {
  const classes = useStyles();
  const { users } = data;
  console.log(data);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          Users
          <Box display="flex" alignItems="center">
            {/* <SearchBar handleSearch="" persist enableClear="" disableFlex /> */}
          </Box>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography style={{ fontSize: "0.8rem" }} variant="h6">
          {data.name}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.root}>
        <div style={{ height: 490 }} className={classes.root}>
          <DataGrid
            rowHeight={38}
            pageSize={10}
            // loading={!rows}
            rowsPerPageOptions={[10, 25, 50, 100]}
            rows={users}
            columns={[
              {
                field: "firstName",
                headerName: "First Name",
                headerClassName: "column-header",
                flex: 1,
                renderCell: (params) => (
                  <Box
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {params.row.firstName}
                  </Box>
                ),
              },

              {
                field: "lastName",
                headerName: "Last Name",
                flex: 1,
                renderCell: (params) => (
                  <Box style={{ marginLeft: "10px" }}>
                    {params.row.lastName}
                  </Box>
                ),
              },

              {
                field: "email",
                headerName: "Email",
                flex: 1,
                renderCell: (params) => (
                  <Box style={{ marginLeft: "10px" }}>{params.row.email}</Box>
                ),
              },
            ]}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Close</Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default Company;
