import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const PackagesTable = () => {

    const [companyData, setCompanyData] = useState<Array<any>>([])

    useEffect(() => {
        topCompaniesData()
    }, [])

    const topCompaniesData = async () => {
        try {
            let url = 'https://datafeed.dev.revos.in/service/bolt/stats/topCompaniesList'
            let res = await fetch(url, {
                headers: { token: '1234' },
                method: "GET",
            })
            let response = await res.json() as Array<any>;
            setCompanyData(response as Array<any>)
         }
        catch (e) {
            return e
         }
    }
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, marginTop: '25px' }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        {/* <TableCell align="center">Package</TableCell> */}
                        <TableCell align="center">Total Calls</TableCell>
                        <TableCell align="center">Avg Execution Time (s)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companyData.map((row) => (
                        <TableRow
                            key={row.companyId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            {/* <TableCell align="center">{row.calories}</TableCell> */}
                            <TableCell align="center">{row.count}</TableCell>
                            <TableCell align="center">{row.avgExecTime}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PackagesTable
