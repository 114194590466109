import React, { FC, useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchBar from "../../../global/SearchBar/index";
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .column-header": {
      fontSize: "13px",
    },
  },
}));

const Devices = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  const [editDialog, setEditDialog] = useState({ open: false, data: {} });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    data: {},
  });
  const FEED_QUERY = gql`
    {
      devicesQ {
        getAllDevices {
          id
          deviceId
          createdAt
          status
        }
      }
    }
  `;

  const { data } = useQuery(FEED_QUERY);

  useEffect(() => {
    if (data) {
      let activatedDevices: any = [];
      data.devicesQ.getAllDevices.filter((el: any) => {
        if (el.status !== "DELETED") {
          return activatedDevices.push(el);
        } else {
          return "";
        }
      });
      setRows(activatedDevices);
      setLoading(false);
    }
    setSearch("");
  }, [data]);

  useEffect(() => {
    let chargeSearch = rows;
    if (search) {
      chargeSearch = chargeSearch.filter(({ deviceId }) =>
        [deviceId].some(
          (el) => el && String(el).toLowerCase().includes(search.toLowerCase())
        )
      );
      setRows(chargeSearch);
    }
  }, [search]);

  const handleClose = () => {
    setDeleteDialog({ ...deleteDialog, open: false });
  };

  console.log(rows);
  const classes = useStyles();

  return (
    <Paper
      elevation={3}
      style={{ padding: 12, margin: 0, border: "0.2px solid #44305660" }}
    >
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" style={{ marginLeft: "5px" }}>
          Devices
        </Typography>
        <Box display="flex" alignItems="center">
          <SearchBar
            handleSearch={setSearch}
            persist
            enableClear={Boolean(search)}
            disableFlex
            placeholder="Search..."
            id="1"
          />
        </Box>
        {/* <Button
          variant="contained"
          disableElevation
          style={{ marginRight: '5px' }}
          startIcon={<AddIcon />}
        >
          New
        </Button> */}
      </Box>
      <Box height={"80vh"} className={classes.root}>
        <DataGrid
          rows={rows || []}
          columns={[
            {
              field: "device id",
              headerName: "Device Id",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <Box style={{ marginLeft: "5px" }}>{params.row.deviceId}</Box>
              ),
            },
            {
              field: "created at",
              headerName: "Created At",
              flex: 1,
              valueFormatter: (params) =>
                moment(params.row.createdAt).format("ddd, MMM DD, YYYY"),
            },

            {
              field: "actions",
              headerName: "Actions",
              headerClassName: "column-header",
              flex: 1,
              renderCell: (params) => (
                <>
                  {/* <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setEditDialog({ open: true, data: params.row });
                    }}
                  >
                    <EditIcon />
                  </IconButton> */}
                  <IconButton
                    onClick={() =>
                      setDeleteDialog({ open: true, data: params.row })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              ),
            },
          ]}
          rowHeight={48}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          loading={loading}
          disableSelectionOnClick
        />
      </Box>
      <DeleteDeviceDialog
        open={deleteDialog.open}
        handleClose={handleClose}
        data={deleteDialog.data}
        FEED_QUERY={FEED_QUERY}
      />
    </Paper>
  );
};

interface DeleteDeviceDialogProps {
  open: boolean;
  data: any;
  handleClose: () => void;
  FEED_QUERY: any;
}

const DeleteDeviceDialog: React.FC<DeleteDeviceDialogProps> = ({
  open,
  data,
  handleClose,
  FEED_QUERY,
}) => {
  console.log(data.id);

  const DELETE_DEVICE = gql`
    mutation deleteDevice($deleteDeviceWhere: DeviceWhereUniqueInput!) {
      devicesM {
        deleteDevice(where: $deleteDeviceWhere) {
          deviceId
          status
        }
      }
    }
  `;

  const [deleteDevice] = useMutation(DELETE_DEVICE, {
    refetchQueries: [{ query: FEED_QUERY }],
  });

  function handleDelete() {
    deleteDevice({
      variables: {
        deleteDeviceWhere: { id: data.id },
      },
    });
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Device</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ minWidth: 250 }}>
          Are you sure you want to delete <strong>"{data.deviceId}"</strong> ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleDelete}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Devices;
