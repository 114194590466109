export const getHealthData = async (type) => {
    let url;
    if(type==='lease')url = 'https://trigger.revos.in/health?type=Master&token=1234';
    else if (type==='bolt') url = `https://datafeed.revos.in/housekeeping/bolt/health?token=1234&id=5cfa443da7b11b00073f9657&master=true`
    try {
      let res = await fetch(url, {method: "GET"})
      if (res) {
        res = await res.json()
        return res.result;
      }
    } catch (e) {
      console.log(e);
      return {data: []}
    }
    
    
    //  return fetch(url, {
    //   method: "GET",
    // })
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((data) => {
    //     return type==='bolt'?data.stats:data.result;
    //   });
  };