import React, { FC, useState, useContext } from "react";
import clsx from "clsx";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import revos from "../../assets/revos.svg";
import { router, adminRouter } from "../../config/routing";
import { NavLink, Route } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import ComputerRoundedIcon from "@material-ui/icons/ComputerRounded";
import { Autorenew } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import Onboard from "../login-signup/Onboard";
import RupeeCampaign from "./rupeeCampaign/index";
import Overview from "./overview/index";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import { DataGridTable } from "./orders";

const drawerWidth = 210;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: "100%",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 10,
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      backgroundColor: "#2c2038",
      color: "#CCCCCC",
      paddingLeft: 5,
    },
    drawerPaper: {},
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    listItemText: {
      fontSize: "0.9rem",
      marginLeft: "-10px",
      color: "#CCCCCC",
    },
    listItemText2: {
      fontSize: "0.7rem",
      color: "#CCCCCC90",
    },

    item: {
      fontSize: "medium",
    },
  })
);

const adminRenderRouter = () => {
  return (
    adminRouter
      // .filter((route) => route.template === "admin")
      .map((route) => {
        return <Route component={route.component} path={"/app" + route.path} />;
      })
  );
};

const renderRouter = () => {
  return router
    .filter((route) => route.template === "deployment")
    .map((route) => {
      return (
        <Route
          component={route.component}
          path={"/app/" + route.template + route.path}
        />
      );
    });
};

const Dashboard: FC = () => {
  const history = useHistory();
  const context: any = useContext(AuthContext);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(true);

  console.log("user", context.user);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    setOpenDropdown(!openDropdown);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />

        <AppBar
          color="inherit"
          style={{ backgroundColor: "#201529" }}
          position="fixed"
          className={clsx(classes.appBar)}
        >
          <Toolbar>
            {context.user.email === "tester@revos.in" ||
            context.user.email === "taijas@bolt.earth" ||
            context.user.userId === "5cfa43c6a7b11b00073f9656" ? (
              <IconButton
                color="inherit"
                style={{ color: "#ffffff" }}
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
                className={clsx(classes.menuButton)}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              ""
            )}

            <img src={revos} style={{ width: "6%" }}></img>
            <Typography
              style={{
                color: "#D0D0D0",
                marginLeft: "10px",
                fontSize: "12px",
                marginBottom: "0px",
                marginTop: "13px",
                letterSpacing: "3px",
              }}
            >
              HouseKeeping
            </Typography>
            <Button
              variant="outlined"
              size="small"
              style={{
                marginRight: 0,
                marginLeft: "auto",
                borderColor: "#ffffff",

                color: "#ffffff",
                width: "100px",
              }}
              onClick={() => {
                history.push("/onboard");
                context.logout();
                localStorage.setItem("authorizationToken", "");
                localStorage.setItem("firebaseToken", "");
              }}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        {context.user.email === "tester@revos.in" ||
        context.user.email === "taijas@bolt.earth" ||
        context.user.userId === "5cfa43c6a7b11b00073f9656" ? (
          <Drawer
            color="inherit"
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx(classes.drawer, classes.drawerPaper, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <List style={{ marginLeft: "7px", marginTop: "70px" }}>
              <ListItem button onClick={handleClick}>
                <ListItemIcon classes={{ root: classes.item }}>
                  <ComputerRoundedIcon
                    style={{ color: "#CCCCCC" }}
                    classes={{ root: classes.item }}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Deployment"
                />
                {openDropdown ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openDropdown} timeout="auto" unmountOnExit>
                <List
                  style={{
                    marginLeft: open ? "7px" : "0px",
                    marginTop: "-10px",
                  }}
                >
                  {router.map((route, index) => (
                    <NavLink
                      to={"/app/" + route.template + route.path}
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontSize: "8px",
                      }}
                    >
                      <ListItem
                        button
                        key={route.label}
                        classes={{ root: classes.item }}
                      >
                        <ListItemIcon>
                          {
                            <route.icon
                              classes={{
                                root: classes.item,
                              }}
                              style={{
                                color: "#CCCCCC",
                              }}
                            />
                          }
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classes.listItemText,
                          }}
                          primary={route.label}
                        />
                      </ListItem>
                    </NavLink>
                  ))}
                </List>
              </Collapse>
            </List>

            <List
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "auto",
                marginBottom: "0",
              }}
            >
              <ListItem>
                {open ? (
                  <ListItemText
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "0.2rem",
                      color: "#61D069",
                    }}
                    classes={{
                      primary: classes.listItemText2,
                    }}
                    color="secondary"
                  >
                    Revos Housekeeping &#169; 2021
                  </ListItemText>
                ) : (
                  <ListItemText
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "0.2rem",
                    }}
                    classes={{
                      primary: classes.listItemText2,
                    }}
                  >
                    v 2.0
                  </ListItemText>
                )}
              </ListItem>
            </List>
          </Drawer>
        ) : (
          ""
        )}
        {context.user.email === "admin@revos.in" ||
        context.user.email === "taijas@bolt.earth" ||
        context.user.userId === "6196458d98f94a00072086c6" ? (
          <Drawer
            color="inherit"
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx(classes.drawer, classes.drawerPaper, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <List style={{ marginLeft: "7px", marginTop: "70px" }}>
              <ListItem button onClick={handleClick}>
                <ListItemIcon classes={{ root: classes.item }}>
                  <ComputerRoundedIcon
                    style={{ color: "#CCCCCC" }}
                    classes={{ root: classes.item }}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Stats"
                />
                {openDropdown ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openDropdown} timeout="auto" unmountOnExit>
                <List
                  style={{
                    marginLeft: open ? "7px" : "0px",
                    marginTop: "-10px",
                  }}
                >
                  {adminRouter.map((route, index) => (
                    <NavLink
                      to={"/app" + route.path}
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontSize: "8px",
                      }}
                    >
                      <ListItem
                        button
                        key={route.label}
                        classes={{ root: classes.item }}
                      >
                        <ListItemIcon>
                          {
                            <route.icon
                              classes={{
                                root: classes.item,
                              }}
                              style={{
                                color: "#CCCCCC",
                              }}
                            />
                          }
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classes.listItemText,
                          }}
                          primary={route.label}
                        />
                      </ListItem>
                    </NavLink>
                  ))}
                </List>
              </Collapse>
            </List>

            <List
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "auto",
                marginBottom: "0",
              }}
            >
              <ListItem>
                {open ? (
                  <ListItemText
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "0.2rem",
                      color: "#61D069",
                    }}
                    classes={{
                      primary: classes.listItemText2,
                    }}
                    color="secondary"
                  >
                    Revos Housekeeping &#169; 2021
                  </ListItemText>
                ) : (
                  <ListItemText
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "0.2rem",
                    }}
                    classes={{
                      primary: classes.listItemText2,
                    }}
                  >
                    v 2.0
                  </ListItemText>
                )}
              </ListItem>
            </List>
          </Drawer>
        ) : (
          ""
        )}
        <main
          className={classes.content}
          style={{
            background:
              "linear-gradient(94deg, rgba(49,34,63,0.55) -20%, rgba(255,255,255,0) 100%)",
            height: "100%",
          }}
        >
          <div className={classes.toolbar} />
          {renderRouter()}
          <Route component={RupeeCampaign} path="/app/rupeecampaign" />
          <Route component={DataGridTable} path="/app/orders" />
          {/* <Route component={Overview} path="/app/overview" /> */}
          {adminRenderRouter()}
        </main>
      </div>
    </ThemeProvider>
  );
};

export default Dashboard;
